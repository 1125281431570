import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import ApiContextProvider from "Services/ContextProvider/ApiContextProvider";

const AdminRoute = ({ component: Component, layout: Layout, ...rest }) => {
  const { adminJsession } = useSelector(
    (state) => ({
      adminJsession: state.auth.adminJsession,
    }),
    shallowEqual
  );
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <Layout>
          {adminJsession ? (
            <>
              <ApiContextProvider>
                <Component {...matchProps} />
              </ApiContextProvider>
            </>
          ) : (
            <Redirect to="/login" />
          )}
        </Layout>
      )}
    />
  );
};
export default AdminRoute;
