export const SET_AUTH_JSESSION = "SET_AUTH_JSESSION";

export const SET_AUTH_ADMIN_JSESSION = "SET_AUTH_ADMIN_JSESSION";

export const SET_SUPPLIER_MODAL = "SET_SUPPLIER_MODAL";

export const SET_CATEGORY_MODAL = "SET_CATEGORY_MODAL";

export const SET_MODEL_MODAL = "SET_MODEL_MODAL";

export const SET_INITIAL_STATE_PERSISTENT_STATE =
  "SET_INITIAL_STATE_PERSISTENT_STATE";

export const SET_PRODUCTS = "SET_PRODUCTS";

export const DEL_PRODUCTS = "DEL_PRODUCTS";

export const SET_ACCOUNTS = "SET_ACCOUNTS";

export const ADD_ACCOUNTS = "ADD_ACCOUNTS";

export const DEL_ACCOUNTS = "DEL_ACCOUNTS";

export const SET_ACCOUNTING_BILLS = "SET_ACCOUNTING_BILLS";

export const DEL_ACCOUNTING_BILLS = "DEL_ACCOUNTING_BILLS";

export const SET_ACCOUNTING_CATEGORIES = "SET_ACCOUNTING_CATEGORIES";

export const DEL_ACCOUNTING_CATEGORIES = "DEL_ACCOUNTING_CATEGORIES";

export const SET_CUSTOMERS = "SET_CUSTOMERS";

export const ADD_CUSTOMERS = "ADD_CUSTOMERS";

export const DEL_CUSTOMERS = "DEL_CUSTOMERS";

export const SET_CUSTOMER_MODAL = "SET_CUSTOMER_MODAL";

export const SET_PAYMENTS = "SET_PAYMENTS";

export const SET_FACETS = "SET_FACETS";

export const SET_USERS = "SET_USERS";

export const DEL_FACETS = "DEL_FACETS";

export const SET_LOADED = "SET_LOADED";

export const SET_ASSETS = "SET_ASSETS";

export const SET_COMPANIES = "SET_COMPANIES";

export const ADD_COMPANIES = "ADD_COMPANIES";

export const DEL_COMPANIES = "DEL_COMPANIES";

export const SET_REFERENCE_BILLS = "SET_REFERENCE_BILLS";

export const DEL_REFERENCE_BILLS = "DEL_REFERENCE_BILLS";

export const SET_GALLERY_IMAGES = "SET_GALLERY_IMAGES";

export const SET_ORDERS = "SET_ORDERS";
