import { SET_AUTH_ADMIN_JSESSION, SET_AUTH_JSESSION } from "../Action/types";

const initialState = {
  jsession: ""
};

function authReducer(state = initialState, action) {
  switch (action.type) {
    case SET_AUTH_JSESSION:
      return Object.assign({}, state, {
        jsession: action.payload
      });
    case SET_AUTH_ADMIN_JSESSION:
      return Object.assign({}, state, {
        adminJsession: action.payload
      });
    // return {
    //   ...state,
    //   jsession: action.payload,
    // };

    default:
      return state;
  }
}
export default authReducer;
