import { useMutation } from "@apollo/client";
import { FilledInput } from "@material-ui/core";
import React from "react";
import API from "Services/API";
const SINGLE_UPLOAD = API.MUTATION.SINGLE_UPLOAD;
const UploadFile = ({ setImageData }) => {
  // const [mutate, { loading, error }] = useMutation(SINGLE_UPLOAD);
  // const onChange = ({
  //   target: {
  //     validity,
  //     files: [file],
  //   },
  // }) => {
  //   console.log({ file });
  //   return validity.valid && mutate({ variables: { file } });
  // };

  // if (loading) return <div>Loading...</div>;
  // if (error) return <div>{JSON.stringify(error, null, 2)}</div>;
  const imageDataFn = ({
    target: {
      validity,
      files: [file]
    }
  }) => {
    validity.valid && setImageData(file);
  };

  return (
    <>
      <input type="file" required onChange={imageDataFn} />
    </>
  );
};
export default UploadFile;
