import { useMutation, useQuery } from "@apollo/client";
import {
  Grid,
  Modal,
  Paper,
  TextField,
  Button,
  CircularProgress,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setCategoryModal, setLoaded } from "Redux/Action/action";
import UploadFile from "Components/AdminContent/FileUpload/uploadFile";
import API from "Services/API";
import { checkImgSrc } from "util/utils";
const ADD_CATEGORY = API.MUTATION.ADD_SUBCATEGORY;
const UPDATE_CATEGORY = API.MUTATION.UPDATE_SUBCATEGORY;
const CategoryModal = () => {
  const dispatch = useDispatch();
  const inputEl = useRef([]);
  const [imageData, setImageData] = useState(null);
  const [getSVal, setGetSVal] = useState({});
  const [colorCode, setColorCode] = useState("#000");
  const [catListSelectedId, setCatListSelectedId] = useState(null);
  const { category_modal, categories } = useSelector(
    (state) => ({
      category_modal: state.page.category_modal,
      categories: state.persistent.categories,
    }),
    shallowEqual
  );
  const type = category_modal.type;
  const modal_data = category_modal.data;
  useEffect(() => {
    if (modal_data?.id) {
    } else setCatListSelectedId(categories?.[0]?.id);
  }, [categories, modal_data]);
  useEffect(() => {
    if (modal_data?.id) {
      categories.map((ele) => {
        console.log("ele", ele);
        const findItem = ele.Subcategories.find(
          (element) => element.id === modal_data.id
        );
        console.log("findItem", findItem);

        if (findItem) {
          setCatListSelectedId(ele?.id);
          setColorCode(findItem?.color ? findItem?.color : "#000");

          setGetSVal(findItem);
        }
      });
    }
    return () => {
      setGetSVal(null);
      setImageData(null);
    };
  }, [modal_data]);

  const handleClose = () => {
    dispatch(setCategoryModal({ modal: false, data: null, type: null }));
  };
  const [addSubCategory, { loading: addLoading }] = useMutation(ADD_CATEGORY, {
    onCompleted: () => {
      dispatch(setLoaded({ categories: false }));
      dispatch(setCategoryModal({ modal: false, data: null, type: null }));
    },
  });
  const [updateSubCategory, { loading: updateLoading }] = useMutation(
    UPDATE_CATEGORY,
    {
      onCompleted: async () => {
        dispatch(setLoaded({ categories: false }));
        dispatch(setCategoryModal({ modal: false, data: null, type: null }));
      },
    }
  );

  const handleAdd = (e) => {
    e.preventDefault();
    const fields = {
      title: inputEl?.current["sub_category_name"]?.value,
      categoryId: catListSelectedId,
      color: colorCode ? colorCode : "#000",
      cover: imageData,
      type: "check",
    };
    console.log(fields);
    try {
      addSubCategory({
        variables: {
          subCategoryFields: fields,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };
  const handleUpdate = (e) => {
    e.preventDefault();
    const fields = {
      title: inputEl?.current["sub_category_name"]?.value,
      categoryId: catListSelectedId,
      color: colorCode ? colorCode : "#000",
      cover: imageData,
      type: "check",
    };
    try {
      updateSubCategory({
        variables: {
          id: ~~modal_data.id,
          subCategoryFields: fields,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };
  if (addLoading || updateLoading) return <CircularProgress size={40} />;
  const Subcategory_data = getSVal;
  const imgSrc = imageData
    ? URL.createObjectURL(imageData)
    : checkImgSrc(Subcategory_data?.cover);

  return (
    <>
      <Modal
        open={category_modal.modal}
        onClose={handleClose}
        aria-labelledby="simple-modal-a"
        aria-describedby="simple-modal-descraiption"
      >
        <div className="modalCenter">
          <Paper>
            <h1>{type === "add" ? "ADD SUBCATEGORY" : "UPDATE SUBCATEGORY"}</h1>
            <div className="flex flex.wrap">
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <div>
                    {imgSrc ? (
                      <img
                        src={imgSrc}
                        width={200}
                        alt={
                          Subcategory_data?.cover
                            ? Subcategory_data?.cover
                            : "CATEGORY"
                        }
                      />
                    ) : (
                      <div
                        style={{
                          width: "200px",
                          height: "200px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        No image.
                      </div>
                    )}
                  </div>
                </Grid>
                <Grid item xs={8}>
                  <UploadFile setImageData={setImageData} />
                </Grid>
                <Grid item xs={6}>
                  <FormControl>
                    <InputLabel id="demo-simple-select-label">
                      Category
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={catListSelectedId}
                      disabled
                      onChange={(e) => {
                        setCatListSelectedId(e.target.value);
                      }}
                    >
                      {categories.map((ele) => (
                        <MenuItem value={ele.id}>{ele.title}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <div
                    style={{
                      width: "20px",
                      height: "20px",
                      background: colorCode,
                    }}
                  ></div>
                  <TextField
                    id="standard-full-width"
                    label="Color code"
                    placeholder="Write color code"
                    value={colorCode}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => {
                      setColorCode(e.target.value);
                    }}
                    inputRef={(el) => (inputEl.current["color_code"] = el)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="standard-full-width"
                    label="Name"
                    placeholder="Write Sub Category name."
                    helperText="Sub Category name must be unique."
                    defaultValue={Subcategory_data?.title}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputRef={(el) =>
                      (inputEl.current["sub_category_name"] = el)
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  {type === "add" ? (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleAdd}
                    >
                      ADD
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleUpdate}
                    >
                      UPDATE
                    </Button>
                  )}
                </Grid>
              </Grid>
            </div>
          </Paper>
        </div>
      </Modal>
    </>
  );
};
export default CategoryModal;
