import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import TextField from "@material-ui/core/TextField";
import { useState } from "react";
import Button from "@material-ui/core/Button";
import { Grid } from "@material-ui/core";

export default function OutlinedChips({ arrayOfHashtags, addHashtag }) {
  const [hashtag, setHashtag] = useState("");
  const handleDelete = (h) => () => {
    addHashtag((arrayOfHashtags) =>
      arrayOfHashtags.filter((hashtag) => hashtag !== h)
    );
  };
  const handleHashtagChange = (event) => setHashtag(event.target.value);

  const handleClick = () => {
    console.info("You clicked the Chip.");
  };
  const newHashtag = () => {
    addHashtag((arrayOfHashtags) => arrayOfHashtags.concat(hashtag));
  };
  const Hashtags = arrayOfHashtags.map((h, i) => (
    <Chip
      key={`chips-${h}-${i}`}
      size="small"
      avatar={<Avatar>#</Avatar>}
      label={h}
      onDelete={handleDelete(h)}
    />
  ));
  return (
    <div>
      <TextField
        size="small"
        inputProps={{
          style: { fontSize: 15 },
        }}
        id="outlined-multiline-static"
        multiline
        rows={1}
        placeholder="Tags"
        variant="outlined"
        value={hashtag}
        onChange={handleHashtagChange}
      />
      <Button
        color="primary"
        disabled={hashtag?.length === 0}
        onClick={newHashtag}
      >
        Add
      </Button>
      <Grid className="mt1" container>
        {Hashtags}
      </Grid>
    </div>
  );
}
