import { useMutation, useQuery } from "@apollo/client";
import React, { useContext } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
// import { useHistory } from "react-router-dom";
import { delCompanies, setLoaded, setAccountModal } from "Redux/Action/action";
import API from "Services/API";

import EnhancedTable from "./EnhancedTable";
import OrderContextProvider, { OrderContext } from "./order.context";
import { useHistory } from "react-router-dom";
const ORDERS = API.QUERY.ORDERS;
const DELETE_COMPANY = API.MUTATION.DELETE_COMPANY;
const AdminOrder = () => {
  return (
    <OrderContextProvider>
      <AdminOrderContent />
    </OrderContextProvider>
  );
};
const AdminOrderContent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [deleteCompany] = useMutation(DELETE_COMPANY);
  const { orders } = useContext(OrderContext);
  const { users } = useSelector(
    (state) => ({
      users: state.persistent.users,
    }),
    shallowEqual
  );
  // const { data, error, loading, refetch } = useQuery(COMPANIES);
  const headRows = [
    // { id: "id", numeric: true, disablePadding: true, label: "id" },
    // {
    //   id: "refId",
    //   numeric: false,
    //   disablePadding: true,
    //   label: "Ref Id",
    //   width: "170px",
    // },
    {
      id: "updatedAt",
      numeric: false,
      disablePadding: true,
      label: "Order Date",
      width: "170px",
    },
    {
      id: "productId",
      numeric: false,
      disablePadding: true,
      label: "Product Name",
      width: "170px",
    },
    {
      id: "color",
      numeric: false,
      disablePadding: true,
      label: "Color",
    },
    {
      id: "size",
      numeric: false,
      disablePadding: true,
      label: "Size",
    },
    {
      id: "status",
      numeric: false,
      disablePadding: true,
      label: "Status",
    },
    {
      id: "qty",
      numeric: false,
      disablePadding: true,
      label: "Quantity",
    },
    {
      id: "type",
      numeric: false,
      disablePadding: true,
      label: "Ordered As",
    },
    {
      id: "firstName",
      numeric: false,
      disablePadding: true,
      label: "First Name",
    },
    {
      id: "lastName",
      numeric: false,
      disablePadding: true,
      label: "Last Name",
    },
    {
      id: "address",
      numeric: false,
      disablePadding: true,
      label: "Address",
    },
    {
      id: "telephone",
      numeric: false,
      disablePadding: true,
      label: "Telephone",
    },
  ];
  const handleOpen = () => {};
  console.log("orders,", orders);
  console.log("users,", users);
  let oldOrders = orders?.slice();
  let newOrders = [];
  oldOrders.map((ele) => {
    let user = {};
    if (ele.userId) {
      const findUser = users?.users?.find((user) => ~~user.id === ~~ele.userId);
      user = {
        firstName: findUser?.firstName,
        lastName: findUser?.lastName,
        address: findUser?.address,
        telephone: findUser?.telephone,
        type: "USER",
      };
    }
    if (ele.guestUserId) {
      const findUser = users?.guestUsers?.find(
        (user) => ~~user.id === ~~ele.guestUserId
      );
      user = {
        firstName: findUser?.firstName,
        lastName: findUser?.lastName,
        address: findUser?.address,
        telephone: findUser?.telephone,
        type: "GUEST",
      };
    }

    newOrders.push({
      ...ele,
      ...user,
    });
  });

  const rows = newOrders;
  function addFn(event) {
    event.preventDefault();
    history.push("orders/create");

    // dispatch(setAccountModal({ modal: true, data: null, type: "add" }));
  }
  function updateFn(event, id) {
    event.preventDefault();
    history.push(`orders/${id}`);

    // dispatch(
    //   setAccountModal({
    //     modal: true,
    //     data: { id: id },
    //     type: "update"
    //   })
    // );
  }
  async function deleteFn(event, id) {
    event.preventDefault();
    const deleteFn = await deleteCompany({ variables: { id } });
    if (deleteFn?.data?.deleteCompany) {
      dispatch(delCompanies(id));
    }
  }
  const refetchFn = () => {
    dispatch(setLoaded({ orders: false, users: false }));
  };
  return (
    <>
      <br />
      {rows.length > 0 && (
        <EnhancedTable
          baseUrl={"/equipments"}
          headRow={headRows}
          rows={rows}
          // history={this.props.history}
          title={"Orders"}
          addFn={addFn}
          updateFn={updateFn}
          deleteFn={deleteFn}
          refetchFn={refetchFn}
        />
      )}
    </>
  );
};
export default AdminOrder;
