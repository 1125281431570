import moment from "moment";

export const replaceDuplicateWithLatest = (oldArray, newArray) => {
  let map = {};
  console.log({ oldArray, newArray });
  const _oldArray = oldArray?.length > 0 ? oldArray : [];
  const _newArray = newArray?.length > 0 ? newArray : [];
  _oldArray.forEach((item) => (map[item.id] = item));
  _newArray.forEach((item) => (map[item.id] = item));

  let result = Object.values(map);
  result.sort((a, b) => {
    return (
      moment.utc(b.updatedAt).diff(moment.utc(a.updatedAt)) ||
      parseInt(b.id) - parseInt(a.id)
    );
  });
  console.log("RESULT", result);
  return result;
};
export function checkImgSrc(imgUrl) {
  const newImg = imgUrl?.search("uploads/");
  return newImg === 0
    ? `${process.env.REACT_APP_PUBLIC_BASE_URL}/${imgUrl}`
    : imgUrl;
}
