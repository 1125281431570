import { useLazyQuery, useMutation } from "@apollo/client";
import { Button, Divider, Grid, Paper } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import API from "Services/API";
import UploadFileW from "Components/AdminContent/FileUpload/uploadFileW";
import { ProductContext } from "./product.context";
import ProductImageList from "./product.imageList";
const FETCH_ASSETS = API.QUERY.FETCH_ASSETS;
const UPLOAD_PRODUCT_PRODUCT_ASSETS =
  API.MUTATION.UPLOAD_PRODUCT_PRODUCT_ASSETS;

const ProductListAssets = () => {
  const { setOpenFileModal, imageList } = useContext(ProductContext);
  const latestImageList = imageList?.map((ele) => ele.id);

  const [fetchImaage, { data, loading, error }] = useLazyQuery(FETCH_ASSETS);

  const { id } = useParams();

  const [
    uploadProductProductAsset,
    { loading: ProductProductAssetLoading, error: ProductProductAssetError }
  ] = useMutation(UPLOAD_PRODUCT_PRODUCT_ASSETS);
  const [productStar, setProductStar] = React.useState(latestImageList);

  // useEffect(() => {
  //   if (data?.fetchAsset) {
  //     setProductStar(data.fetchAsset?.map((ele) => ele.id));
  //   }
  // }, [data]);
  useEffect(() => {
    fetchImaage({ variables: { assets: [] } });
    return () => {};
  }, []);
  const handleAssetAdd = async () => {
    const checkUpload = await uploadProductProductAsset({
      variables: {
        idList: productStar?.map((ele) => {
          return { product_id: parseInt(id), product_asset_id: ele };
        })
      }
    });

    if (checkUpload) {
      setOpenFileModal(false);
    }
  };

  console.log("imageList", imageList);
  return (
    <>
      <div
        style={{
          minWidth: "600px",
          padding: "1rem",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: `translate(-${50}%, -${50}%)`
        }}
      >
        <Paper className="p1">
          <h2 id="product-facet-title">Add product assets</h2>
          <UploadFileW />
          <Divider />
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <ProductImageList
                fetchAsset={data?.fetchAsset}
                productStar={productStar}
                setProductStar={setProductStar}
              />
            </Grid>
            <Grid item xs={4}>
              <img src="https://picsum.photos/200/200" alt="top" />
            </Grid>
          </Grid>
          <Divider />
          <Button variant="outlined" onClick={() => setOpenFileModal(false)}>
            Cancel
          </Button>
          <Button
            variant="containedPrimary"
            disabled={productStar?.length > 0 ? false : true}
            onClick={handleAssetAdd}
          >
            Add {productStar?.length} Asset
          </Button>
        </Paper>
      </div>
    </>
  );
};
export default ProductListAssets;
