import { useMutation } from "@apollo/client";
import { ExitToApp } from "@material-ui/icons";
import EnhancedTable from "Components/Table/EnhancedTable";
import { useContext } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { delProducts, setLoaded } from "Redux/Action/action";
import API from "Services/API";
import Column from "../../DesignComponent/Column";
import GridColumnTemplate from "../../DesignComponent/GridColumn";
import Row from "../../DesignComponent/Row";
import ProductContextProvider, { ProductContext } from "./product.context";
const DELETE_PRODUCT = API.MUTATION.DELETE_PRODUCT;

const Product = () => {
  return (
    <ProductContextProvider>
      <ProductContent />
    </ProductContextProvider>
  );
};
const ProductContent = () => {
  const { products } = useContext(ProductContext);
  const history = useHistory();
  const dispatch = useDispatch();

  const [deleteProduct] = useMutation(DELETE_PRODUCT);
  const headRows = [
    // { id: "id", numeric: true, disablePadding: true, label: "id" },
    {
      id: "updatedAt",
      numeric: false,
      disablePadding: true,
      label: "Date",
      width: "170px",
    },

    {
      id: "title",
      numeric: false,
      disablePadding: true,
      label: "Title",
      width: "170px",
    },
    {
      id: "subtitle",
      numeric: false,
      disablePadding: true,
      label: "Subtitle",
      width: "170px",
    },
    {
      id: "code",
      numeric: false,
      disablePadding: true,
      label: "Code",
    },
    {
      id: "type",
      numeric: false,
      disablePadding: true,
      label: "type",
    },
    {
      id: "price",
      numeric: false,
      disablePadding: true,
      label: "Price",
    },
    {
      id: "tags",
      numeric: true,
      disablePadding: true,
      label: "Tags",
    },
  ];
  const handleOpen = () => {};
  const product_data = products;
  console.log("**8product_data***", product_data);
  const rows = product_data;
  function addFn(event) {
    event.preventDefault();
    history.push("products/create");
    // dispatch(setAccountModal({ modal: true, data: null, type: "add" }));
  }
  function updateFn(event, id) {
    event.preventDefault();
    history.push({
      pathname: `products/${id}`,
      // state: {
      //   row: rows?.find((ele) => ele.id === id)
      // }
    });
  }
  async function deleteFn(event, id) {
    event.preventDefault();
    const deleteFn = await deleteProduct({ variables: { id } });
    if (deleteFn) {
      dispatch(delProducts(parseInt(id)));
      dispatch(setLoaded({ products: false }));
      // refetch();
    }
  }
  const refetchFn = () => {
    // refetch();
    // refetchProducts();
    dispatch(setLoaded({ products: false }));
  };
  return (
    <>
      <EnhancedTable
        headRow={headRows}
        rows={rows}
        // history={this.props.history}
        title={"Product"}
        addFn={addFn}
        updateFn={updateFn}
        deleteFn={deleteFn}
        refetchFn={refetchFn}
      />
    </>
  );
};
export default Product;
