import { Grid } from "@material-ui/core";
import React from "react";
const Column = ({ children, ...props }) => {
  return (
    <>
      <Grid item className="w-100" {...props}>
        {children}
      </Grid>
    </>
  );
};
export default Column;
