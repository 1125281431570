import { useMutation } from "@apollo/client";
import { Button } from "@material-ui/core";
import { useState } from "react";
import API from "Services/API";
const MULTIPLE_UPLOAD = API.MUTATION.MULTIPLE_UPLOAD;
const UPLOAD_PRODUCT_ASSETS = API.MUTATION.UPLOAD_PRODUCT_ASSETS;
const UploadFileW = () => {
  const [imageData, setImageData] = useState(null);
  const [mutate, { loading, error }] = useMutation(UPLOAD_PRODUCT_ASSETS);
  const onChange = (e) => {
    const {
      target: { validity, files }
    } = e;
    return validity.valid && mutate({ variables: { files } });
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{JSON.stringify(error, null, 2)}</div>;

  return (
    <>
      <Button variant="contained" component="label">
        Upload File
        <input
          type="file"
          accept="image/*"
          multiple
          hidden
          onChange={onChange}
        />
      </Button>
    </>
  );
};
export default UploadFileW;
