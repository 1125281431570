import { Checkbox, Chip } from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import IconButton from "@material-ui/core/IconButton";
import {
  Check as CheckIcon,
  NotInterested as NotInterestedIcon,
} from "@material-ui/icons";

import Paper from "@material-ui/core/Paper";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import RefreshIcon from "@material-ui/icons/Refresh";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
let rows = [];
let isHHInfoPage = null;
let isMembers = null;
let baseUrl = null;
let passedParam = null;
let passFilter = {};
let isListItem = null;
let disableBulkImport = false;
function desc(a, b, orderBy) {
  if (b?.[orderBy] < a?.[orderBy]) {
    return -1;
  }
  if (b?.[orderBy] > a?.[orderBy]) {
    return 1;
  }
  return 0;
}
function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}
function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    headRows,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    baseUrl,
    isHHInfoPage,
    queryParam,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "Select all items" }}
          />
        </TableCell>

        {headRows?.map((row, index) => (
          <TableCell
            align={row.numeric ? "right" : "left"}
            // padding={row.disablePadding ? "none" : "default"}
            width={row.width ? row.width : null}
            key={index}
            sortDirection={orderBy === row.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === row.id}
              direction={order}
              onClick={createSortHandler(row.id)}
            >
              {row.label}
            </TableSortLabel>
          </TableCell>
        ))}
        {baseUrl != undefined && (
          <>
            <TableCell padding="checkbox"></TableCell>
            <TableCell padding="checkbox"></TableCell>
          </>
        )}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: "1 1 100%",
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: "0 0 auto",
  },
}));
async function changeStatus(selectedId, rowState, setRowState, setSelected) {
  if (window.confirm("Are you sure to delete this record ?")) {
    var newS = rowState;
    var msg = "";
    if (selectedId && selectedId.length > 0) {
      for (let i = 0; i < selectedId.length; i++) {
        const statusUrl = `${baseUrl}/change_status_id/${selectedId}/0`;
        const options = {
          method: "get",
        };

        await fetch(statusUrl, options)
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            if (data.response === "success") {
              msg = "Delete successful";
              var removeIndex = newS
                .map(function (item) {
                  return item.id;
                })
                .indexOf(selectedId[i]);
              newS.splice(removeIndex, 1);
            }
          });
      }
      alert(msg);
      setRowState(null);
      setRowState(newS);
      setSelected([]);
    }
  }
}
async function deleteItems(
  event,
  url,
  selectedId,
  rowState,
  setRowState,
  setSelected
) {
  const deleteUrl = url + "/delete";
  if (selectedId && selectedId.length > 0) {
    var newS = rowState;
    var msg = null;
    for (let i = 0; i < selectedId.length; i++) {
      await fetch(`${deleteUrl}/${selectedId[i]}`, {
        method: "DELETE",
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          if (
            data.msg === "successfully deleted" ||
            data.msg === "success" ||
            data.response === "success"
          ) {
            msg = "Delete successful";
            if (isHHInfoPage) {
              var removeIndex = newS
                .map(function (item) {
                  return item.code_number;
                })
                .indexOf(selectedId[i]);
              newS.splice(removeIndex, 1);
            } else {
              var removeIndex = newS
                .map(function (item) {
                  return item.id;
                })
                .indexOf(selectedId[i]);
              newS.splice(removeIndex, 1);
            }
            return data;
          }
        });
    }
    alert(msg);
    setRowState(null);
    setRowState(newS);
    setSelected([]);
  }
}

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected, delSelected } = props;
  const dispatch = useDispatch();
  const { addFn, updateFn, refetchFn } = props;
  const delFun = (event) => {
    var delCode = [];
    if (isHHInfoPage) {
      for (let i = 0; i < delSelected.length; i++) {
        props.rowState.filter((code) => {
          if (code.id === delSelected[i]) delCode.push(code.code_number);
        });
      }
      // return true;
    }

    if (isListItem) {
      changeStatus(
        delSelected,
        props.rowState,
        props.setRowState,
        props.setSelected
      );
      return true;
    }
    if (window.confirm("Are you sure to delete this record ?")) {
      deleteItems(
        event,
        props.baseUrl,
        isHHInfoPage ? delCode : delSelected,
        props.rowState,
        props.setRowState,
        props.setSelected
      );
    }
  };

  // function addFun(event) {
  //   dispatch(setAccountModal({ modal: true, data: null, type: "add" }));
  // }
  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <div>
            <Typography variant="h6" id="tableTitle">
              {props.title ? props.title : "Table View"}
            </Typography>
          </div>
        )}
      </div>
      <div className={classes.spacer} />

      {/* Add Button Start */}
      {numSelected <= 0 && (
        <div>
          <Tooltip className="ml-1" onClick={() => refetchFn()} title="Refetch">
            <Fab
              color="primary"
              aria-label="Refetch"
              size="small"
              style={{ float: "right", marginRight: "1rem" }}
              className="ml-3"
            >
              <RefreshIcon />
            </Fab>
          </Tooltip>
        </div>
      )}

      {/* Add Button End */}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: "auto",
  },
}));

export default function EnhancedTable(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { products, payments, facets, image_assets, categories } = useSelector(
    (state) => ({
      products: state.persistent.products,
      payments: state.persistent.payments,
      facets: state.persistent.facets,
      image_assets: state.persistent.image_assets,
      categories: state.persistent.categories,
    }),
    shallowEqual
  );
  const classes = useStyles();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [filterCheck, setFilterCheck] = useState(true);
  const [mouseOverCheck, setMouseOverCheck] = useState(null);
  const [rowState, setRowState] = useState(null);
  const { addFn, updateFn, deleteFn, refetchFn, extraFn } = props;
  useEffect(() => {
    setRowState(props.rows);
  }, [props]);
  passFilter = props.passFilter;
  baseUrl = props.baseUrl;
  passedParam = props.param;
  disableBulkImport = props.disableBulkImport;
  rows = rowState;
  if (rows === null) return null;
  if (typeof rows?.length === "undefined") {
    rows = [];
    setRowState([]);
  }
  isHHInfoPage = props.isHHInfoPage;
  isListItem = props.isListItem;
  isMembers = props.isMembers;
  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  }
  function handleMouseOver(event, id) {
    if (mouseOverCheck !== id) setMouseOverCheck(id);
  }
  function handleMouseOut(event, id) {
    if (mouseOverCheck === id) setMouseOverCheck(null);
  }
  function handleSelectAllClick(event) {
    if (filterCheck && event.target.checked) {
      const newSelecteds = filteredSorts.map((n) => n.id);
      setSelected(newSelecteds);
      setFilterCheck(false);
      return;
    } else {
      setFilterCheck(true);
      setSelected([]);
    }
  }
  function handleRowClick(event, id) {
    event.preventDefault();
    history.push(id);
  }
  function handleClick(event, id) {
    event.preventDefault();
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    } else {
      newSelected = newSelected.concat(selected.slice(1));
    }
    setSelected(newSelected);
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
  }

  function handleChangeDense(event) {
    setDense(event.target.checked);
  }
  function customFunction(arr, row) {
    switch (arr.id) {
      case "featuredImage":
        const featuredImage = row?.["featuredImage"];
        console.log("featuredImage", featuredImage);

        if (featuredImage?.filename) {
          return (
            <img
              src={`${process.env.REACT_APP_PUBLIC_BASE_URL}/${featuredImage?.filename}`}
              alt={`${featuredImage?.filename}`}
              width="50"
            />
          );
        }
        // if (imageAsset) {
        //   console.log(
        //     `${process.env.REACT_APP_PUBLIC_BASE_URL}/${imageAsset?.filename}`
        //   );
        //   return (
        //     <img
        //       src={`${process.env.REACT_APP_PUBLIC_BASE_URL}/${imageAsset?.filename}`}
        //       alt={`${imageAsset?.filename}`}
        //       width="50"
        //     />
        //   );
        // }
        break;
      case "featuredImageId":
        const featuredImageId = row?.["featuredImageId"];
        const imageAsset = image_assets?.find(
          (ele) => ele.id === featuredImageId
        );
        if (imageAsset) {
          console.log(
            `${process.env.REACT_APP_PUBLIC_BASE_URL}/${imageAsset?.filename}`
          );
          return (
            <img
              src={`${process.env.REACT_APP_PUBLIC_BASE_URL}/${imageAsset?.filename}`}
              alt={`${imageAsset?.filename}`}
              width="50"
            />
          );
        }
        break;
      case "facet_values":
        const FacetValues = row?.["FacetValues"];
        return FacetValues?.map((ele) => <Chip label={ele?.code} />);
      case "enabled":
        return row[arr.id] ? <CheckIcon /> : <NotInterestedIcon />;
      case "timeStamp":
        return moment(row[arr.id]).format("YYYY-MM-DD hh:mm");
      case "date":
        return moment(row[arr.id]).format("YYYY-MM-DD");
      case "updatedAt":
        return moment(row[arr.id]).format("YYYY-MM-DD hh:mm");
      case "payment_status_id":
        const Payment = row?.["PaymentStatus"];
        return <Chip label={Payment?.title} />;
      case "supplier_name":
        const Supplier = row?.["Supplier"];
        return <Chip label={Supplier?.name} />;
      case "company_name":
        const Company = row?.["Company"];
        return <Chip label={Company?.name} />;
      case "user_name":
        const User = row?.["User"];
        return <Chip label={User?.userName} />;
      case "productId":
        const productId = row[arr.id];
        const findProduct = products?.find((ele) => ~~ele.id === ~~productId);
        return findProduct ? findProduct?.title : "";
      case "payment_type_id":
        return (
          <Chip
            label={
              payments?.find((ele) => parseInt(ele.id) === row?.[arr.id])?.title
            }
          />
        );
      case "Subcategories":
        let subCatList = [];
        const catL = row?.["Subcategories"];
        if (catL)
          return catL.map((ele) => {
            return <Chip label={ele.title} />;
          });

      case "Purchases":
      case "Sales":
        let total = 0;
        return (
          row?.[arr.id]?.length > 0 && (
            <>
              <div>
                <table style={{ minWidth: "22rem" }}>
                  <thead></thead>
                  <tbody>
                    <tr>
                      <th>Name / Description</th>
                      <th>Qty.</th>
                      <th>Rate</th>
                      <th>Cost</th>
                    </tr>

                    {row?.[arr.id]?.map((ele) => {
                      const currentProduct = products?.find(
                        (element) => element.id === ele.product_id
                      );
                      total = total + ele.rate * ele.quantity;
                      return (
                        <>
                          <tr>
                            <td>
                              <Chip
                                label={currentProduct?.name}
                                color="secondary"
                              />
                            </td>
                            <td>{ele.quantity}</td>
                            <td>{ele.rate}</td>
                            <td>{ele.rate * ele.quantity}</td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                  <hr />

                  <tfoot>
                    <tr>
                      <th colspan="3">Subtotal</th> <td> {total}</td>
                    </tr>
                    <tr>
                      <th colspan="3">Discount</th> <td>-{row.discount}</td>
                    </tr>
                    <tr>
                      <th colspan="3">Tax</th> <td>{row.tax}</td>
                    </tr>
                    <tr>
                      <th colspan="3">Grand Total</th>{" "}
                      <td>{row.grand_total}</td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </>
          )
        );
      case "FacetValues":
        return (
          row?.[arr.id]?.length > 0 && (
            <>
              {row?.[arr.id]?.map((ele) => {
                return (
                  <>
                    <Chip label={ele.code} color="green" />
                  </>
                );
              })}
            </>
          )
        );
      default:
        return row?.[arr.id];
    }
  }

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows?.length - page * rowsPerPage);
  function showM(row) {
    var list = props.headRow.map((arr, index) => {
      return (
        <TableCell
          onClick={(event) =>
            props.viewData ? handleRowClick(event, row.id) : null
          }
          key={index}
          align={arr.numeric ? "right" : "left"}
        >
          {customFunction(arr, row)}
        </TableCell>
      );
    });
    return list;
  }

  var filteredSorts = [];
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          delSelected={selected}
          baseUrl={baseUrl}
          history={history}
          title={props.title}
          rowState={rowState}
          setRowState={setRowState}
          setSelected={setSelected}
          addFn={addFn}
          updateFn={updateFn}
          deleteFn={deleteFn}
          refetchFn={refetchFn}
        />
        <div className={classes.tableWrapper}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              delSelected={selected}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows?.length}
              headRows={props.headRow}
              baseUrl={props.baseUrl}
            />

            <TableBody>
              {stableSort(rows, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(
                    // isHHInfoPage ? row.code_number :
                    row?.id
                  );
                  filteredSorts?.push(row);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  // function gotoEdit(event, id) {
                  //   dispatch(
                  //     setAccountModal({
                  //       modal: true,
                  //       data: { id: id },
                  //       type: "update",
                  //     })
                  //   );
                  // }

                  function singleDelFun(event, code) {
                    if (isListItem) {
                      changeStatus([code], rowState, setRowState, setSelected);
                      return true;
                    }
                    if (
                      window.confirm("Are you sure to delete this record ?")
                    ) {
                      deleteItems(
                        event,
                        props.baseUrl,
                        [code],
                        rowState,
                        setRowState,
                        setSelected
                      );
                    }
                  }

                  function viewDetails(event, codeNo) {
                    history.push("details/" + codeNo);
                  }
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      // onMouseEnter={(event) => handleMouseOver(event, row.id)}
                      // onMouseLeave={(event) => handleMouseOut(event, row.id)}
                      key={row.id}
                      selected={isItemSelected}
                      z
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ "aria-labelledby": labelId }}
                          onClick={(event) =>
                            handleClick(
                              event,
                              // isHHInfoPage ? row.code_number :
                              row.id
                            )
                          }
                        />
                      </TableCell>

                      {/* //mapping */}
                      {showM(row)}
                    </TableRow>
                  );
                })}
              {/* {emptyRows > 0 && (
                <TableRow style={{ height: 10 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )} */}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100, 200]}
          component="div"
          count={rows?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            "aria-label": "Previous Page",
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page",
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
    </div>
  );
}
