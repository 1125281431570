import { replaceDuplicateWithLatest } from "util/utils";
import {
  ADD_ACCOUNTS,
  SET_ASSETS,
  SET_FACETS,
  SET_INITIAL_STATE_PERSISTENT_STATE,
  SET_LOADED,
  SET_PAYMENTS,
  SET_PRODUCTS,
  SET_ACCOUNTS,
  DEL_ACCOUNTS,
  SET_CUSTOMERS,
  ADD_CUSTOMERS,
  DEL_CUSTOMERS,
  SET_COMPANIES,
  ADD_COMPANIES,
  DEL_COMPANIES,
  DEL_PRODUCTS,
  DEL_FACETS,
  SET_REFERENCE_BILLS,
  DEL_REFERENCE_BILLS,
  SET_ACCOUNTING_BILLS,
  DEL_ACCOUNTING_BILLS,
  SET_ACCOUNTING_CATEGORIES,
  DEL_ACCOUNTING_CATEGORIES,
  SET_GALLERY_IMAGES,
  SET_ORDERS,
  SET_USERS,
} from "../Action/types";

const initialState = {
  loaded: {
    products: false,
    payments: false,
    facets: false,
    image_assets: false,
    //not from loading Screen
    accounts: false,
    accountingBills: false,
    categories: false,
    customers: false,
    companies: false,
    referenceBills: false,
    galleryImages: false,
    orders: false,
    users: false,
  },
  products: [],
  payments: [],
  facets: [],
  image_assets: [],
  //not from loading Screen
  customers: [],
  accounts: [],
  accountingBills: [],
  categories: [],
  companies: [],
  referenceBills: [],
  galleryImages: [],
  orders: [],
  users: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_INITIAL_STATE_PERSISTENT_STATE: {
      return Object.assign({}, state, {
        ...initialState,
      });
    }
    case SET_PRODUCTS: {
      const oldLoadedData = state.loaded;
      const newProducts = action.payload;

      let oldProductsData = state.products;
      oldProductsData = oldProductsData.filter((ele) => {
        return newProducts.length > 0
          ? newProducts.some((f) => {
              return f.id !== ele.id;
            })
          : true;
      });
      return Object.assign({}, state, {
        products: newProducts?.concat(oldProductsData),
        loaded: { ...oldLoadedData, products: true },
      });

      // const oldLoadedData = state.loaded;
      // return Object.assign({}, state, {
      //   products: action.payload,
      //   loaded: { ...oldLoadedData, products: true }
      // });
    }
    case DEL_PRODUCTS: {
      const id = parseInt(action.payload);
      const oldProductsData = state.products;
      const newProductsData = oldProductsData.filter((ele) => ele.id !== id);

      return Object.assign({}, state, {
        products: newProductsData,
      });
    }
    case SET_ACCOUNTS: {
      const oldLoadedData = state.loaded;
      const newAccounts = action.payload;

      let oldAccountsData = state.accounts;
      return Object.assign({}, state, {
        accounts: replaceDuplicateWithLatest(oldAccountsData, newAccounts),
        loaded: { ...oldLoadedData, accounts: true },
      });
    }
    case ADD_ACCOUNTS: {
      const oldLoadedData = state.loaded;
      const newAccounts = action.payload;
      let oldAccountsData = state.accounts;

      return Object.assign({}, state, {
        accounts: replaceDuplicateWithLatest(oldAccountsData, newAccounts),
        loaded: { ...oldLoadedData, accounts: true },
      });
    }
    case DEL_ACCOUNTS: {
      const id = parseInt(action.payload);
      const oldSupplierData = state.accounts;
      const newSupplierData = oldSupplierData.filter((ele) => ele.id !== id);

      return Object.assign({}, state, {
        accounts: newSupplierData,
      });
    }

    //ACCOUNTING BILLS
    case SET_ACCOUNTING_BILLS: {
      const oldLoadedData = state.loaded;
      const newAccountingBills = action.payload;
      let oldAccountingBillsData = state.accountingBills;

      return Object.assign({}, state, {
        accountingBills: replaceDuplicateWithLatest(
          oldAccountingBillsData,
          newAccountingBills
        ),
        loaded: { ...oldLoadedData, accountingBills: true },
      });
    }
    case DEL_ACCOUNTING_BILLS: {
      const id = parseInt(action.payload);
      const oldSupplierData = state.accountingBills;
      const newSupplierData = oldSupplierData.filter((ele) => ele.id !== id);
      console.log({ oldSupplierData, newSupplierData });
      return Object.assign({}, state, {
        accountingBills: newSupplierData,
      });
    }
    //END ACCOUNTING BILLS
    //ACCOUNTING BILLS
    case SET_ACCOUNTING_CATEGORIES: {
      const oldLoadedData = state.loaded;
      const newcategories = action.payload;
      let oldcategoriesData = state.categories;
      console.log({ newcategories });
      return Object.assign({}, state, {
        categories: newcategories,
        loaded: { ...oldLoadedData, categories: true },
      });
    }
    case DEL_ACCOUNTING_CATEGORIES: {
      const id = parseInt(action.payload);
      const oldSupplierData = state.categories;
      const newSupplierData = oldSupplierData.filter((ele) => ele.id !== id);

      return Object.assign({}, state, {
        categories: newSupplierData,
      });
    }
    //END ACCOUNTING BILLS

    case SET_CUSTOMERS: {
      const oldLoadedData = state.loaded;
      const newCustomers = action.payload;
      let oldCustomersData = state.customers;
      // oldCustomersData = oldCustomersData.filter((ele) => {
      //   return newCustomers.length > 0
      //     ? newCustomers.some((f) => {
      //         return f.id !== ele.id;
      //       })
      //     : true;
      // });

      return Object.assign({}, state, {
        // customers: newCustomers?.concat(oldCustomersData),
        customers: replaceDuplicateWithLatest(oldCustomersData, newCustomers),
        loaded: { ...oldLoadedData, customers: true },
      });
    }
    case ADD_CUSTOMERS: {
      const oldLoadedData = state.loaded;
      const oldCustomerData = state.customers;
      return Object.assign({}, state, {
        customers: oldCustomerData.concat(action.payload),
        loaded: { ...oldLoadedData, customers: true },
      });
    }
    case DEL_CUSTOMERS: {
      const id = parseInt(action.payload);
      const oldCustomerData = state.accounts;
      const newCustomerData = oldCustomerData.filter((ele) => ele.id !== id);

      return Object.assign({}, state, {
        customers: newCustomerData,
      });
    }

    case SET_PAYMENTS: {
      const oldLoadedData = state.loaded;
      return Object.assign({}, state, {
        payments: action.payload,
        loaded: { ...oldLoadedData, payments: true },
      });
    }
    case SET_FACETS: {
      const oldLoadedData = state.loaded;
      const newFacets = action.payload;

      let oldFacetsData = state.facets;

      return Object.assign({}, state, {
        // facets: newFacets.concat(oldFacetsData),
        facets: replaceDuplicateWithLatest(oldFacetsData, newFacets),
        loaded: { ...oldLoadedData, facets: true },
      });
    }
    case DEL_FACETS: {
      const id = parseInt(action.payload);
      const oldFacetsData = state.facets;
      const newFacetsData = oldFacetsData.filter((ele) => ele.id !== id);

      return Object.assign({}, state, {
        facets: newFacetsData,
      });
    }
    case SET_ASSETS: {
      const oldLoadedData = state.loaded;
      return Object.assign({}, state, {
        image_assets: action.payload,
        loaded: { ...oldLoadedData, image_assets: true },
      });
    }
    case SET_LOADED: {
      const oldLoadedData = state.loaded;
      const newLoaded = action.payload || {};
      return Object.assign({}, state, {
        loaded: { ...oldLoadedData, ...newLoaded },
      });
    }

    case SET_COMPANIES: {
      const oldLoadedData = state.loaded;
      const newCompanies = action.payload;

      let oldCompaniesData = state.companies;
      oldCompaniesData = oldCompaniesData.filter((ele) => {
        return newCompanies.length > 0
          ? newCompanies.some((f) => {
              return f.id !== ele.id;
            })
          : true;
      });
      return Object.assign({}, state, {
        companies: newCompanies?.concat(oldCompaniesData),
        loaded: { ...oldLoadedData, companies: true },
      });
    }
    case ADD_COMPANIES: {
      const oldLoadedData = state.loaded;
      const oldCompaniesData = state.companies;
      return Object.assign({}, state, {
        companies: oldCompaniesData.concat(action.payload),
        loaded: { ...oldLoadedData, companies: true },
      });
    }
    case DEL_COMPANIES: {
      const id = parseInt(action.payload);
      const oldCompaniesData = state.companies;
      const newCompaniesData = oldCompaniesData.filter((ele) => ele.id !== id);

      return Object.assign({}, state, {
        companies: newCompaniesData,
      });
    }

    case SET_REFERENCE_BILLS: {
      const oldLoadedData = state.loaded;
      const newReferenceBills = action.payload;

      let oldReferenceBillsData = state.referenceBills;
      oldReferenceBillsData = oldReferenceBillsData?.filter((ele) => {
        return newReferenceBills.length > 0
          ? newReferenceBills.some((f) => {
              return f.id !== ele.id;
            })
          : true;
      });
      return Object.assign({}, state, {
        referenceBills: newReferenceBills.concat(oldReferenceBillsData),
        loaded: { ...oldLoadedData, referenceBills: true },
      });
    }
    case DEL_REFERENCE_BILLS: {
      const id = parseInt(action.payload);
      const oldReferenceData = state.referenceBills;
      const newReferenceData = oldReferenceData.filter((ele) => ele.id !== id);

      return Object.assign({}, state, {
        referenceBills: newReferenceData,
      });
    }

    case SET_GALLERY_IMAGES: {
      return Object.assign({}, state, {
        galleryImages: action.payload,
        loaded: { ...state.loaded, galleryImages: true },
      });
    }

    case SET_ORDERS: {
      const oldLoadedData = state.loaded;
      const newOrders = action.payload;

      let oldOrders = state.orders;

      return Object.assign({}, state, {
        // facets: newFacets.concat(oldFacetsData),
        orders: replaceDuplicateWithLatest(oldOrders, newOrders),
        loaded: { ...oldLoadedData, orders: true },
      });
    }
    case SET_USERS: {
      const oldLoadedData = state.loaded;
      const newAllUsers = action.payload;

      let oldUsers = state.users;
      const users = oldUsers.users;
      const guestUsers = oldUsers.guestUsers;

      const newUsers = newAllUsers.users;
      const newGuestUsers = newAllUsers.guestUsers;
      const joinUser = replaceDuplicateWithLatest(users, newUsers);
      const joinGuestUser = replaceDuplicateWithLatest(
        guestUsers,
        newGuestUsers
      );

      return Object.assign({}, state, {
        // facets: newFacets.concat(oldFacetsData),
        users: {
          users: joinUser,
          guestUsers: joinGuestUser,
        },
        loaded: { ...oldLoadedData, users: true },
      });
    }

    default:
      return state;
  }
}
