import { Modal, Paper } from "@material-ui/core";
import React from "react";

export default function Login(props) {
  const { open, handleCloseLoginModal } = props;

  return (
    <>
      <Modal
        open={false}
        onClose={handleCloseLoginModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <>
          <Paper>LOGIN</Paper>
        </>
      </Modal>
    </>
  );
}
