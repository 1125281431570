import { useMutation } from "@apollo/client";
import {
  Button,
  Chip,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  Modal,
  Paper,
  Select,
  Snackbar,
  TextField,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { Column, Row } from "Components/DesignComponent";
import React, { useContext, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { setLoaded } from "Redux/Action/action";
import API from "Services/API";
import shortid from "shortid";
import OutlinedChips from "../Category/OutlinedChips";
import OutlinedImages from "../Category/OutlinedImages";
import ProductContextProvider, { ProductContext } from "./product.context";
import ProductListAssets from "./product.listAssets";
const ADD_PRODUCT = API.MUTATION.ADD_PRODUCT;
const UPDATE_PRODUCT = API.MUTATION.UPDATE_PRODUCT;

const ProductCreate = () => {
  return (
    <ProductContextProvider>
      <ProductCreateContent />
    </ProductContextProvider>
  );
};

const ProductCreateContent = () => {
  const { id } = useParams();
  const [arrayOfHashtags, addHashtag] = useState([]);
  const [hashImages, setHashimages] = useState([]);
  const [hashImageKey, setHashImageKey] = useState(shortid.generate());
  const [categoryId, setCategoryId] = useState(null);
  const [subcategoryId, setSubCategoryId] = useState(0);

  const { facets, image_assets, categories } = useSelector(
    (state) => ({
      facets: state.persistent.facets,
      image_assets: state.persistent.image_assets,
      categories: state.persistent.categories,
    }),
    shallowEqual
  );
  useEffect(() => {
    setCategoryId(categories?.[0]?.id);
    if (categories?.[0]?.Subcategories)
      setSubCategoryId(categories?.[0]?.Subcategories?.[0]?.id);
  }, [categories]);
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const formEl = React.useRef();
  const {
    open,
    setOpen,
    openFileModal,
    setOpenFileModal,
    isEnabled,
    setIsEnabled,
    openSnackBar,
    setOpenSnackBar,
    nameList,
    setNameList,
    imageList,
    setImageList,
    list,
    setList,
    newList,
    uploadimageList,
    setUploadImageList,

    setNewList,
    products,

    featuredImageId,
    setfeaturedImageId,
  } = useContext(ProductContext);

  const product_id = parseInt(id);
  const getData = products?.find((ele) => ele.id === product_id);

  // const [
  //   fetchProductAssetId,
  //   {
  //     loading: product_asset_loading,
  //     error: product_asset_error,
  //     refetch: product_asset_refetch,
  //   },
  // ] = useLazyQuery(FETCH_PRODUCTS_ASSET_ID, {
  //   fetchPolicy: "no-cache",
  //   variables: { product_id: parseInt(product_id) },
  //   onCompleted: (arg) => {
  //     if (arg) {
  //       const fetchProductAssetId = arg?.fetchProductAssetId;
  //       if (fetchProductAssetId?.length > 0) {
  //         let imageData = [];
  //         fetchProductAssetId.map((id) => {
  //           const product_assets_image = image_assets?.find(
  //             (ele) => ele.id === id
  //           );
  //           imageData.push(product_assets_image);
  //         });
  //         setImageList(imageData);
  //       }
  //     }
  //   },
  // });
  useEffect(() => {
    if (product_id && getData) {
      setImageList(getData?.images);
      setHashimages(getData?.images);
      addHashtag(getData?.tags);
      setHashImageKey(shortid.generate());
    }
    return () => {
      setHashimages([]);
      addHashtag([]);
    };
  }, [getData]);
  const row = getData;

  useEffect(() => {
    if (id && !row) {
      history.push("/products");
    }
    if (row) {
      setList(row?.FacetValues?.map((ele) => ele.id));
      const { title, type, price, subtitle, content } = formEl.current;
      title.value = row.title;
      type.value = row.type;
      price.value = row.price;
      subtitle.value = row.subtitle;
      content.value = row.content;
      // setImageList(imageData);
      categories?.map((ele) => {
        const subcat = ele.Subcategories;
        subcat.map((el) => {
          if (~~el.id === ~~row.subCategoryId) {
            setCategoryId(ele.id);
            setSubCategoryId(row.subCategoryId);
          }
        });
      });
      // refetchProducts();
    }
  }, [row, id]);

  const [createProduct, { loading: createLoading }] = useMutation(ADD_PRODUCT, {
    onCompleted: (arg) => {
      setOpenSnackBar(true);
      setList([]);
      setNewList([]);
      setIsEnabled(true);
      dispatch(setLoaded({ products: false }));

      // refetchProducts();
    },
  });
  const [updateProduct, { loading: updateLoading }] = useMutation(
    UPDATE_PRODUCT,
    {
      onCompleted: (arg) => {
        setOpenSnackBar(true);
        dispatch(setLoaded({ products: false }));
        history.push("/products");

        // refetchProducts();
      },
    }
  );
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  React.useEffect(() => {
    let nameList = [];
    facets?.map((ele) => {
      const facetValues = ele.FacetValues;
      facetValues.map((elem) => {
        nameList.push({
          id: elem.id,
          code: `${ele.code}: ${elem.code}`,
        });
      });
    });
    setNameList(nameList);
  }, [facets]);

  React.useEffect(() => {
    if (list) {
      const handleList = list?.map((ele) => {
        return {
          facetValue_id: ele,
          code: nameList?.find((elem) => elem.id === ele)?.code,
        };
      });
      setNewList(handleList);
    }
  }, [list, nameList]);

  const handleSubmit = (e) => {
    e.preventDefault();
    // const {
    //   product_name,
    //   product_slug,
    //   product_barcode,
    //   product_unit,
    //   product_cost_price,
    //   product_market_price
    // } = e.target;
    if (subcategoryId <= 0) {
      formEl.current["subcategory-id-input"].focus();
      return;
    }

    const { title, type, price, subtitle, content } = formEl.current;
    let productVariable = {
      title: title?.value,
      name: title?.value,
      code: title?.value,
      type: type?.value,
      price: parseFloat(price?.value),
      subtitle: subtitle?.value,
      tags: arrayOfHashtags,
      // images: hashImages,
      content: content?.value,
      subCategoryId: subcategoryId,
      images: uploadimageList,
      oldImages: imageList ? imageList : [],
    };

    if (id) {
      updateProduct({
        variables: { productFields: productVariable, id: ~~id },
      });
    } else
      createProduct({
        variables: { productFields: productVariable },
      });
  };
  return (
    <div className="p2">
      <>
        <Row>
          <Column>
            <form ref={formEl} onSubmit={handleSubmit}>
              <Grid container spacing={1}>
                <Grid item xs={8}>
                  <Paper className="p2">
                    <span>
                      <h1>PRODUCT {id ? "UPDATE" : "CREATE"}</h1>
                    </span>
                    <Grid container spacing={4} className="p2">
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          required
                          id="title"
                          label="Product Title"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          required
                          id="type"
                          label="Type"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          required
                          type={"number"}
                          id="price"
                          label="Price"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl>
                          <InputLabel htmlFor="category-id-input">
                            Category
                          </InputLabel>
                          <Select
                            native
                            value={categoryId}
                            variant="outlined"
                            onChange={(e) => {
                              setCategoryId(~~e.target.value);
                              setSubCategoryId(0);
                            }}
                            inputProps={{
                              name: "category",
                              id: "category-id-input",
                            }}
                          >
                            {categories?.map((ele) => {
                              return (
                                <option value={ele.id}>{ele.title}</option>
                              );
                            })}
                          </Select>
                        </FormControl>

                        <FormControl>
                          <div className="ml2">
                            <InputLabel htmlFor="subcategory-id-input">
                              Subcategory
                            </InputLabel>
                            <Select
                              native
                              variant="outlined"
                              value={subcategoryId}
                              displayEmpty
                              onChange={(e) => {
                                setSubCategoryId(~~e.target.value);
                              }}
                              inputProps={{
                                name: "subcategory",
                                id: "subcategory-id-input",
                              }}
                            >
                              <option value={0}>None</option>

                              {categories
                                ?.find((ele) => ~~ele.id === categoryId)
                                ?.Subcategories?.map((ele) => {
                                  return (
                                    <option value={ele.id}>{ele.title}</option>
                                  );
                                })}
                            </Select>
                          </div>
                        </FormControl>
                      </Grid>
                      {/* <Grid item xs={6}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          required
                          id="code"
                          label="Code"
                        />
                      </Grid> */}
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          required
                          id="subtitle"
                          label="Subtitle"
                        />
                      </Grid>

                      <Grid item xs={6}>
                        {/* <TextField
                          fullWidth
                          variant="outlined"
                          required
                          type="number"
                          id="tags"
                          label="Tags"
                        /> */}
                        <OutlinedChips
                          arrayOfHashtags={arrayOfHashtags}
                          addHashtag={addHashtag}
                          key={shortid.generate()}
                        />
                      </Grid>
                      {/* <Grid item xs={6}>
                        <Button
                          variant="contained"
                          color="default"
                          startIcon={<Add />}
                          onClick={handleOpen}
                        >
                          FACETS
                        </Button>
                      </Grid> */}
                      <Grid item xs={6}>
                        <TextField
                          multiline
                          fullWidth
                          variant="outlined"
                          required
                          minRows={8}
                          id="content"
                          label="Content"
                        />
                      </Grid>
                    </Grid>
                    <FacetsForm list={list} setList={setList} />

                    {/* <Modal
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="product-facet-title"
                      aria-describedby="product-facet-description"
                    >
                      <div
                        style={{
                          minWidth: "450px",
                          padding: "1rem",
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: `translate(-${50}%, -${50}%)`,
                        }}
                      >
                        <Paper className="p1">
                          <h2 id="product-facet-title">Add facets</h2>
                          <FacetsForm list={list} setList={setList} />
                          <MultipleSelect
                            list={list}
                            setList={setList}
                            names={nameList}
                          />
                        </Paper>
                      </div>
                    </Modal> */}
                    {/* {newList?.map((value) => {
                      return (
                        <>
                          <Chip key={value?.id} label={value?.code} />
                        </>
                      );
                    })} */}
                  </Paper>
                </Grid>
                <Grid item xs={4}>
                  <Modal
                    open={openFileModal}
                    onClose={() => setOpenFileModal(false)}
                    aria-labelledby="product-asset-title"
                    aria-describedby="product-asset-description"
                  >
                    <ProductListAssets
                      setOpenFileModal={setOpenFileModal}
                      imageList={imageList}
                    />
                  </Modal>
                  <Paper>
                    <OutlinedImages
                      hashImages={hashImages}
                      setHashimages={setHashimages}
                      testKey={hashImageKey}
                    />
                    {/* <ImageUploadFn /> */}
                    {/* <ImageContainer
                    setOpenFileModal={setOpenFileModal}
                    product_id={id}
                    featuredImageId={row?.featuredImageId}
                    imageList={imageList}
                    setImageList={setImageList}
                    product_asset_refetch={product_asset_refetch}
                  /> */}
                  </Paper>
                </Grid>

                {/* <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={isEnabled}
                        onChange={() => setIsEnabled(!isEnabled)}
                        name="checkedA"
                        id="product_enabled"
                        inputProps={{ "aria-label": "secondary checkbox" }}
                      />
                    }
                    label="Enabled"
                  />
                </Grid> */}

                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={createLoading || updateLoading}
                    startIcon={
                      createLoading || updateLoading ? (
                        <CircularProgress size={20} color="secondary" />
                      ) : (
                        <Add />
                      )
                    }
                    type="submit"
                  >
                    SUBMIT
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Column>
        </Row>
      </>
      <Snackbar
        open={openSnackBar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackBar(false)}
      >
        <Alert severity="success">
          Product successfully {`${id ? "updated." : "created."}`}!
        </Alert>
      </Snackbar>
    </div>
  );
};
export default ProductCreate;
// {ProductCategoriesJson?.map((ele) => {
//   return (
//     <>
//       <Row>
//         <Column>
//           <FacetOption
//             title={ele.title}
//             subtitle={ele.key}
//           />
//         </Column>
//       </Row>
//     </>
//   );
// })}
// const FacetOption = ({ title, subtitle }) => {
//   const [open, setOpen] = useState(false);
//   const { facets } = useSelector(
//     (state) => ({
//       facets: state.persistent.facets,
//     }),
//     shallowEqual
//   );
//   const list = facets?.find((ele) => {
//     console.log("ele.code", ele.code);
//     console.log("ele.dubit", ele.code === subtitle);
//     return ele.code === subtitle;
//   });
//   const handleOpen = () => {
//     setOpen(true);
//   };
//   const handleClose = () => {
//     setOpen(false);
//   };

//   const Facetlist = list?.FacetValues;
//   return (
//     <>
//       <Button
//         variant="contained"
//         color="default"
//         startIcon={<Add />}
//         onClick={handleOpen}
//       >
//         {title}
//       </Button>
//       <Row>
//         <Column>{subtitle}</Column>
//       </Row>
//       <Modal
//         open={open}
//         onClose={handleClose}
//         aria-labelledby="product-facet-title"
//         aria-describedby="product-facet-description"
//       >
//         <div
//           style={{
//             minWidth: "450px",
//             padding: "1rem",
//             position: "absolute",
//             top: "50%",
//             left: "50%",
//             transform: `translate(-${50}%, -${50}%)`,
//           }}
//         >
//           <Paper className="p1">
//             <h2 id="product-facet-title">{title}</h2>
//           </Paper>
//         </div>
//       </Modal>
//     </>
//   );
// };

const FacetsForm = ({ list, setList }) => {
  const { facets, image_assets } = useSelector(
    (state) => ({
      facets: state.persistent.facets,
      image_assets: state.persistent.image_assets,
    }),
    shallowEqual
  );
  const handleClick = (id) => {
    let oldList = list?.slice();
    const findItem = oldList?.find((old) => ~~old === ~~id);
    if (findItem) {
      const newList = oldList?.filter((el) => el !== id);
      setList(newList);
    } else {
      oldList.push(id);
      setList(oldList);
    }
  };
  return (
    <>
      {facets?.map((ele) => (
        <>
          <Row>
            <Column> {ele.code}</Column>
            <Column>
              {ele.FacetValues?.map((val) => {
                const itemFound = list.includes(val.id);
                return (
                  <>
                    <Chip
                      label={val.code}
                      color={itemFound ? "primary" : ""}
                      onClick={() => handleClick(val.id)}
                    />
                  </>
                );
              })}
            </Column>
          </Row>
        </>
      ))}
    </>
  );
};
