import React, { useContext, useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import { ProductContext } from "Components/AdminContent/Product/product.context";
import { Column, Row } from "Components/DesignComponent";
import { CancelSharp } from "@material-ui/icons";

const ImageUploadFn = () => {
  const {
    uploadimageList,
    setUploadImageList,
    imageList,
    setImageList,

    featuredImageId,
    setfeaturedImageId,

    removeImageFromList,
  } = useContext(ProductContext);
  return (
    <>
      <div className="p2">
        <Row>
          {imageList?.map((ele) => {
            const newImg = ele?.search("uploads/");
            const setImg =
              newImg === 0
                ? `${process.env.REACT_APP_PUBLIC_BASE_URL}/${ele}`
                : newImg;
            const previewImage = `${setImg}`;
            if (ele)
              return (
                <Column sm={12} md={4} lg={4} className={`p2 `}>
                  <div className="product--container">
                    <span
                      className="product--close "
                      onClick={() => {
                        removeImageFromList(ele);
                      }}
                    >
                      <CancelSharp />
                    </span>
                    <img
                      className={`preview my20 featuredImage cursorPointer ${
                        ele.id === featuredImageId ? "active" : ""
                      }`}
                      onClick={() => {
                        setfeaturedImageId(ele.id);
                      }}
                      src={previewImage}
                      alt=""
                      width={80}
                    />
                  </div>
                </Column>
              );
          })}
        </Row>
        <Row>{uploadimageList?.length > 0 && <>New Attachments</>}</Row>
        <Row>
          {uploadimageList?.map((ele) => {
            const previewImageURL = URL.createObjectURL(ele);
            return (
              <Column sm={12} md={4} lg={4} className="p2">
                <img
                  className="preview my20"
                  src={previewImageURL}
                  alt=""
                  width={80}
                />
              </Column>
            );
          })}
        </Row>

        <Row className="mt2">
          <Column>
            <label htmlFor="image-upload-input">
              <input
                accept="image/*"
                id="image-upload-input"
                multiple
                type="file"
                style={{ display: "none" }}
                onChange={(e) => {
                  const {
                    target: { validity, files },
                  } = e;
                  setUploadImageList([...uploadimageList, ...files]);
                }}
              />
              <Button variant="contained" component="span">
                Upload
              </Button>
            </label>
          </Column>
        </Row>
      </div>
    </>
  );
};
export default ImageUploadFn;
