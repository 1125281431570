import { CircularProgress } from "@material-ui/core";
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  setAdminJsession,
  setInitialStatePersistentState
} from "Redux/Action/action";

const Logout = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  React.useEffect(() => {
    dispatch(setAdminJsession(""));
    dispatch(setInitialStatePersistentState());
  });
  return (
    <>
      <div
        style={{
          height: "100%",
          width: "100%",
          display: " flex",
          justifyContent: "center",
          alignItems: " center",
          minHeight: "calc(100vh - 4rem)"
        }}
      >
        <CircularProgress size={80} color="secondary" />
        <span style={{ marginLeft: "1rem" }}>Logging out...</span>
      </div>
    </>
  );
};
export default Logout;
