import { useLazyQuery, useQuery } from "@apollo/client";
import React, { createContext, useEffect, useState } from "react";
import API from "Services/API";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setOrders } from "Redux/Action/action";
import moment from "moment";

export const OrderContext = createContext();
const ORDERS = API.QUERY.ORDERS;
const OrderContextProvider = ({ children }) => {
  const dispatch = useDispatch();
  const { loaded, orders } = useSelector(
    (state) => ({
      loaded: state.persistent.loaded,
      orders: state.persistent.orders,
    }),
    shallowEqual
  );
  const [open, setOpen] = React.useState(false);
  const [openFileModal, setOpenFileModal] = React.useState(false);
  const [isEnabled, setIsEnabled] = React.useState(true);
  const [openSnackBar, setOpenSnackBar] = React.useState(false);
  const [nameList, setNameList] = React.useState([]);
  const [imageList, setImageList] = React.useState([]);

  const [list, setList] = React.useState([]);
  const [newList, setNewList] = React.useState([]);
  const [fetchOrders] = useLazyQuery(ORDERS, {
    fetchPolicy: "no-cache",
    onCompleted: ({ getOrders }) => {
      console.log("ORDERS", getOrders);
      dispatch(setOrders(getOrders));
    },
  });
  useEffect(() => {
    if (!loaded.orders)
      fetchOrders({
        variables: {
          fromDate: orders?.[0]?.updatedAt
            ? moment(orders?.[0]?.updatedAt)?.toDate()
            : "",
          orderBy: "DESC",
        },
      });
    return () => {
      // cleanup
    };
  }, [loaded]);
  console.log("orders", orders);
  return (
    <OrderContext.Provider
      value={{
        open,
        setOpen,
        openFileModal,
        setOpenFileModal,
        isEnabled,
        setIsEnabled,
        openSnackBar,
        setOpenSnackBar,
        nameList,
        setNameList,
        imageList,
        setImageList,
        list,
        setList,
        newList,
        setNewList,
        orders,
      }}
    >
      {children}
    </OrderContext.Provider>
  );
};
export default OrderContextProvider;
