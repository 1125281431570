import React from "react";
import { Route } from "react-router-dom";
import { Fade } from "@material-ui/core";

const PublicRoute = ({ component: Component, layout: Layout, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <Layout>
          <Fade>
            <Component {...matchProps} />
          </Fade>
        </Layout>
      )}
    />
  );
};
export default PublicRoute;
