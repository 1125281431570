import { useMutation, useQuery } from "@apollo/client";
import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setCategoryModal, setLoaded } from "Redux/Action/action";
import API from "Services/API";

import EnhancedTable from "./EnhancedTable";
const DELETE_CATEGORY = API.MUTATION.DELETE_CATEGORY;
const UserPage = () => {
  const dispatch = useDispatch();

  const { users } = useSelector(
    (state) => ({
      users: state.persistent.users,
    }),
    shallowEqual
  );
  const [deleteCategory] = useMutation(DELETE_CATEGORY);
  const headRows = [
    {
      id: "firstName",
      numeric: false,
      disablePadding: true,
      label: "First Name",
      width: "170px",
    },
    {
      id: "lastName",
      numeric: false,
      disablePadding: true,
      label: "Last Name",
      width: "170px",
    },
    {
      id: "address",
      numeric: false,
      disablePadding: true,
      label: "Last Name",
      width: "170px",
    },
    {
      id: "address2",
      numeric: false,
      disablePadding: true,
      label: "Address 2",
      width: "170px",
    },
    {
      id: "cityTown",
      numeric: false,
      disablePadding: true,
      label: "City/Town",
      width: "170px",
    },
    {
      id: "postCodeZip",
      numeric: false,
      disablePadding: true,
      label: "Postcode/zip",
      width: "170px",
    },
    {
      id: "email",
      numeric: false,
      disablePadding: true,
      label: "Email",
      width: "170px",
    },
    {
      id: "telephone",
      numeric: false,
      disablePadding: true,
      label: "Telephone",
      width: "170px",
    },
  ];
  const handleOpen = () => {};
  const rows = users?.users;
  function addFn(event) {
    event.preventDefault();
    dispatch(setCategoryModal({ modal: true, data: null, type: "add" }));
  }
  function updateFn(event, id) {
    event.preventDefault();
    dispatch(
      setCategoryModal({
        modal: true,
        data: { id: id },
        type: "update",
      })
    );
  }
  async function deleteFn(event, id) {
    event.preventDefault();
    const deleteFn = await deleteCategory({ variables: { id } });
    if (deleteFn?.data?.deleteParentCategory?.success) {
      dispatch(setLoaded({ users: false }));
    }
  }
  const refetchFn = () => {
    dispatch(setLoaded({ users: false }));
  };
  return (
    <>
      <br />
      <EnhancedTable
        baseUrl={"/Users"}
        headRow={headRows}
        rows={rows}
        // history={this.props.history}
        title={"Users"}
        addFn={addFn}
        updateFn={updateFn}
        deleteFn={deleteFn}
        refetchFn={refetchFn}
      />
    </>
  );
};
export default UserPage;
