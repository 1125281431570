import {
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar
} from "@material-ui/core";
import { StarBorder as StarBorderIcon } from "@material-ui/icons";
import React from "react";
const ProductImageList = ({ fetchAsset, productStar, setProductStar }) => {
  return (
    <ImageList
      sx={{
        width: 500,
        height: 200,
        // Promote the list into its own layer in Chrome. This costs memory, but helps keeping high FPS.
        transform: "translateZ(0)"
      }}
      style={{
        maxHeight: 300
      }}
      rowHeight={200}
      gap={1}
      className="productImageList"
    >
      {fetchAsset?.map((ele) => {
        return (
          <>
            <ImageListItem key={ele?.filename} cols={1} rows={1}>
              <img
                src={`${process.env.REACT_APP_PUBLIC_BASE_URL}/${ele?.filename}`}
                alt="top"
                width="50"
                loading="lazy"
                style={{ width: "50px !important" }}
              />
              <ImageListItemBar
                sx={{
                  background:
                    "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
                    "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)"
                }}
                position="top"
                title={ele?.filename}
                onClick={() => {
                  var newDataIndex = productStar.indexOf(ele.id);

                  if (newDataIndex >= 0) {
                    setProductStar(productStar.filter((pro) => pro !== ele.id));
                  } else {
                    setProductStar([...productStar, ele.id]);
                  }
                }}
                actionIcon={
                  <IconButton
                    sx={{ color: "white" }}
                    aria-label={`star ${ele?.filename}`}
                  >
                    <StarBorderIcon
                      style={{
                        color: productStar?.find((elem) => {
                          return elem === ele.id;
                        })
                          ? "yellow"
                          : "white"
                      }}
                    />
                  </IconButton>
                }
                actionPosition="left"
              />
            </ImageListItem>
          </>
        );
      })}
    </ImageList>
  );
};
export default ProductImageList;
