import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import TextField from "@material-ui/core/TextField";
import { useState } from "react";
import Button from "@material-ui/core/Button";
import { Collapse, Grid } from "@material-ui/core";
import { Widgets } from "@material-ui/icons";
import { Column, Row } from "Components/DesignComponent";
import shortid from "shortid";
import ImageUploadFn from "../FileUpload/ImageUpload";

export default function OutlinedImages({ hashImages, setHashimages, testKey }) {
  const [hashtag, setHashtag] = useState("");
  const handleDelete = (h) => () => {
    setHashimages((hashImages) =>
      hashImages?.filter((hashtag) => hashtag !== h)
    );
  };
  const handleHashtagChange = (event) => setHashtag(event.target.value);

  const handleClick = () => {
    console.info("You clicked the Chip.");
  };
  const newHashtag = () => {
    setHashimages((hashImages) => hashImages?.concat(hashtag));
  };
  const Hashtags = ({ hashImages }) => {
    return hashImages?.map((h) => {
      const newImg = h?.search("uploads/");

      return (
        <>
          <Row className="mt2" key={`image-${h}`}>
            <Column xs={4}>
              <img
                src={
                  newImg === 0
                    ? `${process.env.REACT_APP_PUBLIC_BASE_URL}/${h}`
                    : h
                }
                alt={h}
                style={{ width: "50px" }}
              />
            </Column>
            <Column xs={8}>
              <Chip
                style={{
                  overflow: "hidden",
                  width: "14rem",
                }}
                size="small"
                avatar={<Avatar>#</Avatar>}
                label={h}
                onDelete={handleDelete(h)}
              />
            </Column>
          </Row>
        </>
      );
    });
  };
  return (
    <div className="p2 " key={testKey}>
      <Row>
        <Column>Add image From file uploads</Column>
      </Row>
      <Row>
        <Column>
          <ImageUploadFn />
        </Column>
      </Row>

      {/* <Row>
        <Column>Add image From URL</Column>
      </Row>
      <Row>
        <Column>
          <TextField
            size="small"
            inputProps={{
              style: { fontSize: 15 },
            }}
            id="outlined-multiline-static"
            multiline
            rows={1}
            placeholder="Add image url."
            variant="outlined"
            fullWidth
            value={hashtag}
            onChange={handleHashtagChange}
          />
        </Column>
      </Row>

      <Hashtags hashImages={hashImages} /> */}
      {/* <Grid container>
        <Button
          color="primary"
          disabled={hashtag?.length === 0}
          onClick={newHashtag}
        >
          Add
        </Button>
      </Grid> */}
    </div>
  );
}
