import { Grid } from "@material-ui/core";
import React from "react";
const Row = ({ children, ...props }) => {
  return (
    <>
      <Grid container {...props}>
        {children}
      </Grid>
    </>
  );
};
export default Row;
