import { useMutation } from "@apollo/client";
import { CircularProgress } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { Column, Row } from "Components/DesignComponent";
import React from "react";
import { useDispatch } from "react-redux";
import {
  setAdminJsession,
  setInitialStatePersistentState,
} from "Redux/Action/action";
import API from "Services/API";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
const SIGN_ON = API.MUTATION.SIGN_ON;

export default function AdminLogin() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [signOn, { loading, error }] = useMutation(SIGN_ON, {
    onCompleted: (arg) => {
      if (arg) {
        dispatch(setInitialStatePersistentState());
        dispatch(setAdminJsession(arg?.signIn?.token));
      }
    },
    onError: (arg) => {
      if (arg) {
        const { graphQLErrors, networkError } = arg;
        if (graphQLErrors)
          graphQLErrors.forEach(({ message, locations, path, extensions }) => {
            if (extensions.code === ("UNAUTHENTICATED" || "Session Expired")) {
              console.log("UNAUTHENTICATED");
              dispatch(setAdminJsession(""));
            }
            console.log(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            );
          });
        if (networkError) {
          dispatch(setAdminJsession(""));

          console.log(`[Network error]: ${networkError}`);
        }
      }
    },
  });
  const handleLogin = (e) => {
    e.preventDefault();

    const email = e.target.email.value;
    const password = e.target.password.value;
    signOn({ variables: { email, password, orderFields: [] } });
  };
  return (
    <div className="admin-login-layout">
      <Row className="justifyContent-center center height-full ">
        <Column>
          <div className="container-design">
            <div className="circle circle-one"></div>
            <div className="circle circle-two"></div>
            <div className={`container-login pb3 pt3 pl2 pr2 `}>
              <Avatar className={classes.avatar}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Sign in
              </Typography>
              <form className={classes.form} onSubmit={handleLogin}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                />
                <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label="Remember me"
                />
                {error ? (
                  <span style={{ color: "coral" }}>
                    Sign in Error. Please try again.
                  </span>
                ) : (
                  ""
                )}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={loading}
                  className={classes.submit}
                >
                  Sign In{" "}
                  {loading ? (
                    <CircularProgress
                      className="ml2"
                      size={15}
                      color="secondary"
                    />
                  ) : (
                    ""
                  )}
                </Button>
                <Grid container>
                  <Grid item xs>
                    <Link href="#" variant="body2">
                      Forgot password?
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link href="#" variant="body2">
                      {"Don't have an account? Sign Up"}
                    </Link>
                  </Grid>
                </Grid>
              </form>
            </div>
          </div>
        </Column>
      </Row>
      {/* <Container component="main">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form className={classes.form} onSubmit={handleLogin}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            {error ? (
              <span style={{ color: "coral" }}>
                Sign in Error. Please try again.
              </span>
            ) : (
              ""
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={loading}
              className={classes.submit}
            >
              Sign In{" "}
              {loading ? (
                <CircularProgress className="ml2" size={15} color="secondary" />
              ) : (
                ""
              )}
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container> */}
    </div>
  );
}
