import { IconButton } from "@material-ui/core";
import { Menu } from "@material-ui/icons";
import React, { useState } from "react";
import AdminMenuBar from "../Components/NavBar/AdminMenuBar";
import AdminSideBar from "../Components/NavBar/AdminSideBar";
const AdminPanelLayout = ({ children }) => {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [sideBarOpen, setSideBarOpen] = useState(true);

  return (
    <>
      <div>
        {/* <AdminMenuBar
          onMobileNavOpen={() => setMobileNavOpen(true)}
          sideBarOpen={sideBarOpen}
          setSideBarOpen={setSideBarOpen}
        /> */}
        <AdminSideBar
          onMobileClose={() => setMobileNavOpen(false)}
          openMobile={isMobileNavOpen}
          sideBarOpen={sideBarOpen}
        />
        <div
          style={{
            paddingLeft: sideBarOpen
              ? "calc( 190px + 1rem)"
              : "calc( 72px + 1rem)",
          }}
        >
          <div className="ml1 mr1">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => setSideBarOpen(!sideBarOpen)}
              edge="start"
            >
              <Menu />
            </IconButton>
          </div>
          <div className="ml2 mr2">{children}</div>
        </div>
      </div>
    </>
  );
};
export default AdminPanelLayout;
