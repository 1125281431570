import { combineReducers } from "redux";
import authReducer from "./AuthReducer";
import pageReducer from "./PageReducer";
import PersistentReducer from "./PersistentReducer";

// const rootReducer = combineReducers({
//   auth: authReducer,
// });
// export default rootReducer;
export default combineReducers({
  auth: authReducer,
  page: pageReducer,
  persistent: PersistentReducer
});
