import { useMutation, useQuery } from "@apollo/client";
import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { setCategoryModal, setLoaded } from "Redux/Action/action";
import API from "Services/API";
import CategoryModal from "./categoryModal";

import EnhancedTable from "./EnhancedTable";
const CATEGORIES = API.QUERY.CATEGORIES;
const DELETE_CATEGORY = API.MUTATION.DELETE_CATEGORY;
const Category = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { categories, image_assets, galleryImages, loaded } = useSelector(
    (state) => ({
      categories: state.persistent.categories,
      image_assets: state.persistent.image_assets,
      galleryImages: state.persistent.galleryImages,
      loaded: state.persistent.loaded,
    }),
    shallowEqual
  );
  const [deleteCategory] = useMutation(DELETE_CATEGORY);
  const { data, error, loading, refetch } = useQuery(CATEGORIES);
  const headRows = [
    {
      id: "title",
      numeric: false,
      disablePadding: true,
      label: "Title",
      width: "170px",
    },
    {
      id: "Subcategories",
      numeric: false,
      disablePadding: true,
      label: "Subcategories",
      width: "170px",
    },
  ];
  const handleOpen = () => {};
  if (loading) return <>LOADING</>;
  const rows = categories;
  function addFn(event) {
    event.preventDefault();
    // history.go("/create");
    dispatch(setCategoryModal({ modal: true, data: null, type: "add" }));
  }
  function updateFn(event, id) {
    event.preventDefault();
    dispatch(
      setCategoryModal({
        modal: true,
        data: { id: id },
        type: "update",
      })
    );
  }
  async function deleteFn(event, id) {
    event.preventDefault();
    const deleteFn = await deleteCategory({ variables: { id } });
    if (deleteFn?.data?.deleteParentCategory?.success) {
      dispatch(setLoaded({ categories: false }));
    }
  }
  const refetchFn = () => {
    // refetch();
    dispatch(setLoaded({ categories: false }));
  };
  return (
    <>
      <br />
      <EnhancedTable
        baseUrl={"/Category"}
        headRow={headRows}
        rows={rows}
        // history={this.props.history}
        title={"Category"}
        addFn={addFn}
        updateFn={updateFn}
        deleteFn={deleteFn}
        refetchFn={refetchFn}
      />
      <CategoryModal />
    </>
  );
};
export default Category;
