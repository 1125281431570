import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Button, Chip } from "@material-ui/core";
import { Column, Row } from "Components/DesignComponent";
import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setGalleryImage, setLoaded } from "Redux/Action/action";
import API from "Services/API";
import UploadFileW from "../FileUpload/uploadFileW";
const UPLOAD_FACET_IMAGE = API.MUTATION.UPLOAD_FACET_IMAGE;
const GALLERY_IMAGES = API.QUERY.GALLERY_IMAGES;

function GalleryPage() {
  const dispatch = useDispatch();
  const { facets, image_assets, galleryImages, loaded } = useSelector(
    (state) => ({
      facets: state.persistent.facets,
      image_assets: state.persistent.image_assets,
      galleryImages: state.persistent.galleryImages,
      loaded: state.persistent.loaded,
    }),
    shallowEqual
  );
  const [uploadImageFacetValue] = useMutation(UPLOAD_FACET_IMAGE, {
    onCompleted: ({ uploadImageFacetValue }) => {
      // console.log("uploadImageFacetValue", uploadImageFacetValue);
      // setOpenSnackBar(true);
      dispatch(setLoaded({ galleryImages: false }));

      // refetchProducts();
    },
  });
  const [galleryImageFn] = useLazyQuery(GALLERY_IMAGES, {
    fetchPolicy: "no-cache",
    onCompleted: ({ galleryImages }) => {
      dispatch(setGalleryImage(galleryImages));
      // dispatch(setLoaded({ galleryImages: true }));

      // dispatch(setAccountingCategories(galleryImages));
    },
  });
  useEffect(() => {
    galleryImageFn();
  }, [loaded.galleryImages]);

  const uploadImageGallery = ({ catId, subCatId, file }) => {
    if (catId && subCatId) {
      console.log({
        catId,
        subCatId,
        file,
      });
      uploadImageFacetValue({
        variables: { catId: ~~catId, subCatId: ~~subCatId, file },
      });
    }
  };
  const categoriesList = facets?.find((ele) => ele.code === "category");
  const productTypesList = facets?.find((ele) => ele.code === "product_type");
  return (
    <div>
      <Row>
        <Column>GALLERY</Column>
      </Row>
      <Row className="ml2">
        <Column>
          {categoriesList?.FacetValues?.map((cat) => {
            return (
              <>
                <Row>
                  <Column>
                    {cat.code}
                    {productTypesList?.FacetValues?.map((ele) => {
                      const findFile = galleryImages?.find(
                        (elem) =>
                          ~~elem.catId === ~~cat.id &&
                          ~~elem.subCatId === ~~ele.id
                      );
                      const findFileName = findFile?.filename;

                      console.log("findFile", findFile);
                      const previewImage = `${process.env.REACT_APP_PUBLIC_BASE_URL}/${findFileName}`;
                      const catId = cat.id;
                      const subCatId = ele.id;
                      const handleOnChange = (file) => {
                        uploadImageGallery({
                          catId,
                          subCatId,
                          file,
                        });
                      };
                      return (
                        <Row className="ml2 mt1">
                          <Column>
                            <div class="flex">
                              <div className="gallery-title">{ele.code}</div>
                              <div className="gallery-btn-1">
                                {findFileName ? (
                                  <div
                                    className="flex"
                                    key={`image-upload-${cat.id}-${ele.id}-${findFileName}`}
                                  >
                                    <img
                                      src={previewImage}
                                      alt="image-file"
                                      width={80}
                                    />
                                  </div>
                                ) : (
                                  <>No Image </>
                                )}
                                <label
                                  htmlFor={`image-upload-${cat.id}-${ele.id}`}
                                >
                                  <input
                                    accept="image/*"
                                    // id="image-upload-input"
                                    type="file"
                                    id={`image-upload-${cat.id}-${ele.id}`}
                                    style={{ display: "none" }}
                                    onChange={(e) => {
                                      const {
                                        target: { validity, files },
                                      } = e;
                                      const file = files?.[0];
                                      handleOnChange(file);

                                      //   setUploadImageList([...uploadimageList, ...files]);
                                    }}
                                  />
                                  <Button variant="contained" component="span">
                                    Upload
                                    {`cat-${cat.id}-${ele.id}`}
                                  </Button>
                                </label>
                                {/* <Button variant="contained">Upload</Button> */}
                              </div>
                              <div className="gallery-btn-2">
                                <Button variant="contained">Preview</Button>
                              </div>
                            </div>
                          </Column>
                        </Row>
                      );
                    })}
                  </Column>
                </Row>
              </>
            );
          })}
        </Column>
      </Row>
    </div>
  );
}

export default GalleryPage;
