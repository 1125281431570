import {
  Avatar,
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  Typography,
} from "@material-ui/core";
import {
  Dashboard as DashboardIcon,
  LibraryBooks as SupplierIcon,
  Person as AccountIcon,
  Settings as SettingsIcon,
  Storefront as ShoppingBagIcon,
  SupervisedUserCircle as UsersIcon,
  Business as CompanyIcon,
  ShoppingCart as PurchaseIcon,
  Receipt as SaleICon,
  LocalOffer as LocalOfferIcon,
  ExitToApp as ExitToAppIcon,
  Menu,
} from "@material-ui/icons";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import NavItem from "./NavItem";

const user = {
  avatar: "/static/images/avatars/avatar_6.png",
  // jobTitle: "Tensei",
  name: "MONARCH",
};

const items = [
  // {
  //   href: "/dashboard",
  //   icon: DashboardIcon,
  //   title: "Dashboard",
  // },
  // {
  //   href: "/accountings",
  //   icon: SaleICon,
  //   title: "Accounting",
  // },
  // {
  //   href: "/accounts",
  //   icon: SaleICon,
  //   title: "Accounts",
  // },
  {
    href: "/orders",
    icon: UsersIcon,
    title: "Orders",
  },
  {
    href: "/products",
    icon: ShoppingBagIcon,
    title: "Products",
  },
  // {
  //   href: "/gallery",
  //   icon: ShoppingBagIcon,
  //   title: "Gallery",
  // },

  // {
  //   href: "/stock-list",
  //   icon: SaleICon,
  //   title: "Stock List",
  // },
  // {
  //   href: "/sales",
  //   icon: SaleICon,
  //   title: "Sales",
  // },
  // {
  //   href: "/purchases",
  //   icon: PurchaseIcon,
  //   title: "Purchases",
  // },
  {
    href: "/categories",
    icon: UsersIcon,
    title: "Categories",
  },

  // {
  //   href: "/customers",
  //   icon: UsersIcon,
  //   title: "Customers",
  // },
  // {
  //   href: "/suppliers",
  //   icon: SupplierIcon,
  //   title: "Suppliers",
  // },

  // {
  //   href: "/companies",
  //   icon: CompanyIcon,
  //   title: "Company"
  // },
  // {
  //   href: "/account",
  //   icon: AccountIcon,
  //   title: "Account"
  // },
  // {
  //   href: "/settings",
  //   icon: SettingsIcon,
  //   title: "Settings"
  // },
  // {
  //   href: "/facets",
  //   icon: LocalOfferIcon,
  //   title: "Facets",
  // },
  {
    href: "/users",
    icon: LocalOfferIcon,
    title: "Users",
  },

  {
    href: "/logout",
    icon: ExitToAppIcon,
    title: "Logout",
  },
];

const AdminSideBar = ({ onMobileClose, openMobile, sideBarOpen }) => {
  const location = useLocation();
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          p: 2,
        }}
      >
        <Avatar
          component={RouterLink}
          src={user.avatar}
          sx={{
            cursor: "pointer",
            width: 64,
            height: 64,
          }}
          to="/app/account"
        />
        {sideBarOpen && (
          <>
            <Typography color="textPrimary" variant="h5">
              {user.name}
            </Typography>
            <Typography color="textSecondary" variant="body2">
              {user.jobTitle}
            </Typography>
          </>
        )}
      </Box>
      <Divider />
      <Box sx={{ p: sideBarOpen ? 2 : 0 }}>
        <List>
          {items.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={sideBarOpen ? item.title : ""}
              icon={item.icon}
            />
          ))}
        </List>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
    </Box>
  );

  return (
    <>
      {/* <Hidden>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: {
              width: 256
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden> */}
      <>
        <div class="sidebar-menu">
          <Drawer
            anchor="left"
            open
            variant="persistent"
            PaperProps={{
              lg: {
                width: 256,
                height: "calc(100% - 64px)",
              },
            }}
            className="admin-side-bar"
          >
            {content}
          </Drawer>
        </div>
      </>
    </>
  );
};

AdminSideBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

AdminSideBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false,
};

export default AdminSideBar;
