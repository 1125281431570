import {
  SET_SUPPLIER_MODAL,
  SET_CATEGORY_MODAL,
  SET_MODEL_MODAL,
} from "../Action/types";

const initialState = {
  supplier_modal: {
    modal: false,
    data: null,
    type: null,
  },
  category_modal: {
    modal: false,
    data: null,
    type: null,
  },
  model_modal: {
    modal: false,
    data: null,
    type: null,
  },
};
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_SUPPLIER_MODAL:
      return Object.assign({}, state, {
        supplier_modal: action.payload,
      });
    case SET_CATEGORY_MODAL:
      return Object.assign({}, state, {
        category_modal: action.payload,
      });
    case SET_MODEL_MODAL:
      return Object.assign({}, state, {
        model_modal: action.payload,
      });
    default:
      return state;
  }
}
