import { useLazyQuery } from "@apollo/client";
import { CircularProgress } from "@material-ui/core";
import moment from "moment";
import React, { createContext } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  setFacets,
  setPayments,
  setImageAssets,
  setProducts,
  setAccounts,
  setCustomers,
  setAccountingBills,
  setcategories,
  setOrders,
  setUsers,
} from "Redux/Action/action";
import API from "../API";
const PRODUCTS = API.QUERY.PRODUCTS;
const PAYMENTS = API.QUERY.PAYMENTS;
const ACCOUNTS = API.QUERY.ACCOUNTS;
const ACCOUNTING_BILLS = API.QUERY.ACCOUNTING_BILLS;
const CATEGORIES = API.QUERY.CATEGORIES;
const CUSTOMERS = API.QUERY.CUSTOMERS;
const FACETS = API.QUERY.FACETS;
const USERS = API.QUERY.USERS;
const FETCH_ASSETS = API.QUERY.FETCH_ASSETS;
const ORDERS = API.QUERY.ORDERS;

export const ApiContext = createContext();
const ApiContextProvider = ({ children }) => {
  const dispatch = useDispatch();
  const {
    loaded,
    products,
    facets,
    accounts,
    customers,
    accountingBills,
    categories,
    orders,
    users,
  } = useSelector(
    (state) => ({
      loaded: state.persistent.loaded,
      products: state.persistent.products,
      facets: state.persistent.facets,
      accounts: state.persistent.accounts,
      accountingBills: state.persistent.accountingBills,
      categories: state.persistent.categories,
      customers: state.persistent.customers,
      orders: state.persistent.orders,
      users: state.persistent.users,
    }),
    shallowEqual
  );
  const field = {};
  let old_products = [];

  React.useMemo(() => {
    if (products) {
      old_products = products?.splice();
      if (old_products?.[0]?.updatedAt) {
        field.fromDate = moment(old_products?.[0]?.updatedAt)?.toDate();
        field.orderBy = "DESC";
      }
    }
  }, [products]);

  const [
    refetchProducts,
    { loading: re_product_loading, error: re_product_error },
  ] = useLazyQuery(PRODUCTS, {
    fetchPolicy: "no-cache",
    variables: field,
    onCompleted: (value) => {
      const reproducts = value?.products;
      if (reproducts) {
        reproducts?.map((ele) => {
          const foundIndex = old_products?.findIndex(
            (element) => element.id === ele.id
          );
          old_products[foundIndex] = ele;
        });
        dispatch(setProducts([...reproducts, ...old_products]));
      }
    },
  });
  // const [fetchAccounts] = useLazyQuery(ACCOUNTS, {
  //   fetchPolicy: "no-cache",
  //   onCompleted: ({ Accounts: newAccounts }) => {
  //     dispatch(setAccounts(newAccounts));
  //   },
  // });
  // const [fetchAccountingBills] = useLazyQuery(ACCOUNTING_BILLS, {
  //   fetchPolicy: "no-cache",
  //   onCompleted: ({ accountingBills: newAccountingBills }) => {
  //     dispatch(setAccountingBills(newAccountingBills));
  //   },
  // });

  const [fetchcategories] = useLazyQuery(CATEGORIES, {
    fetchPolicy: "no-cache",
    onCompleted: ({ getCategories }) => {
      console.log({ getCategories });
      dispatch(setcategories(getCategories));
    },
  });

  // const [fetchCustomer] = useLazyQuery(CUSTOMERS, {
  //   fetchPolicy: "no-cache",
  //   onCompleted: ({ customers: newCustomers }) => {
  //     dispatch(setCustomers(newCustomers));
  //   },
  // });
  const [
    fetchProducts,
    {
      loading: product_loading,
      error: product_error,
      refetch: product_refetch,
    },
  ] = useLazyQuery(PRODUCTS, {
    fetchPolicy: "no-cache",
    // variables: {
    //   orderBy: "DESC",
    //   fromDate: moment().format(),
    // },
    onCompleted: (arg) => {
      if (arg) dispatch(setProducts(arg?.getProducts));
    },
  });
  const [fetchOrders] = useLazyQuery(ORDERS, {
    fetchPolicy: "no-cache",
    onCompleted: ({ getOrders }) => {
      dispatch(setOrders(getOrders));
    },
  });

  const [fetchUsers, { loading: users_loading, error: users_error }] =
    useLazyQuery(USERS, {
      fetchPolicy: "no-cache",

      onCompleted: (arg) => {
        if (arg) dispatch(setUsers(arg?.getUsers));
      },
    });
  // const [fetchAssets, { loading: assets_loading, error: assets_error }] =
  //   useLazyQuery(FETCH_ASSETS, {
  //     variables: { assets: [] },
  //     onCompleted: (args) => {
  //       if (args?.fetchAsset) dispatch(setImageAssets(args?.fetchAsset));
  //     },
  //   });
  React.useEffect(() => {
    if (!loaded.products) {
      fetchProducts({
        variables: {
          fromDate: products?.[0]?.updatedAt
            ? moment(products?.[0]?.updatedAt)?.toDate()
            : "",
        },
      });
    }
    if (!loaded.users) {
      fetchUsers({
        variables: {
          fromDate: users?.[0]?.updatedAt
            ? moment(users?.[0]?.updatedAt)?.toDate()
            : "",
        },
      });
    }
    if (!loaded.orders)
      fetchOrders({
        variables: {
          fromDate: orders?.[0]?.updatedAt
            ? moment(orders?.[0]?.updatedAt)?.toDate()
            : "",
          orderBy: "DESC",
        },
      });
    // if (!loaded.accounts)
    //   fetchAccounts({
    //     variables: {
    //       fromDate: accounts?.[0]?.updatedAt
    //         ? moment(accounts?.[0]?.updatedAt)?.toDate()
    //         : "",
    //       orderBy: "DESC",
    //     },
    //   });
    // if (!loaded.accountingBills)
    //   fetchAccountingBills({
    //     variables: {
    //       fromDate: accountingBills?.[0]?.updatedAt
    //         ? moment(accountingBills?.[0]?.updatedAt)?.toDate()
    //         : "",
    //       orderBy: "DESC",
    //     },
    //   });
    if (!loaded.categories) {
      console.log("====");
      fetchcategories({
        variables: {
          orderBy: "DESC",
        },
      });
    }

    // if (!loaded.customers)
    //   fetchCustomer({
    //     variables: {
    //       fromDate: customers?.[0]?.updatedAt
    //         ? moment(customers?.[0]?.updatedAt)?.toDate()
    //         : "",
    //       orderBy: "DESC",
    //     },
    //   });
    // if (!loaded.facets) {
    //   fetchFacets({
    //     variables: {
    //       fromDate: facets?.[0]?.updatedAt
    //         ? moment(facets?.[0]?.updatedAt)?.toDate()
    //         : "",
    //       orderBy: "DESC",
    //     },
    //   });
    // }
    // if (!loaded.assets) {
    //   fetchAssets();
    // }
    return () => {};
  }, [loaded]);
  if (product_loading || product_error) {
    return (
      <>
        <div
          style={{
            height: "100%",
            width: "100%",
            display: " flex",
            justifyContent: "center",
            alignItems: " center",
            minHeight: "calc(100vh - 4rem)",
          }}
        >
          <CircularProgress size={80} color="secondary" />
        </div>
      </>
    );
  }
  return (
    <ApiContext.Provider value={[refetchProducts, { re_product_loading }]}>
      {children}
    </ApiContext.Provider>
  );
};
export default ApiContextProvider;
