import {
  ApolloClient,
  ApolloProvider,
  from,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { Snackbar } from "@material-ui/core";
import { createUploadLink } from "apollo-upload-client";
import {
  AdminLogin,
  Category,
  Product,
  ProductCreate,
} from "Components/AdminContent";
// import AccountCreate from "Components/AdminContent/Accounts/AccountList/create";
import GalleryPage from "Components/AdminContent/Gallery";
import AdminOrder from "Components/AdminContent/Order";
import UserPage from "Components/AdminContent/User/user";
import Logout from "Components/Logout/Logout";
import { createBrowserHistory } from "history";
import AdminLayout from "Layouts/AdminLayout";
import LoginLayout from "Layouts/LoginLayout";
import MasterLayout from "Layouts/MasterLayout";
import NoRoute from "Layouts/NoRoute";
import { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Redirect, Switch } from "react-router-dom";
import AdminRoute from "Router/AdminRoute";
import LoginRoute from "Router/LoginRoute";
import PublicRoute from "Router/PublicRoute";
import { BaseUrl } from "Services/utils";
import ThemeProvider from "Theme/Context";
import ThemeSetter from "Theme/Setter";
import {
  setAdminJsession,
  setInitialStatePersistentState,
} from "./Redux/Action/action";
import "SCSS/LandingPage.scss";
function App() {
  const [openSnackbar, setOpenSnackBar] = useState(false);
  const [snackbarMessage, setOpenSnackBarMessage] = useState("");

  const handleOpenSnackbar = () => {
    setOpenSnackBar(true);
  };
  const handleCloseSnackbar = () => {
    setOpenSnackBar(false);
  };
  const { adminJsession } = useSelector(
    (state) => ({
      adminJsession: state.auth.adminJsession,
    }),
    shallowEqual
  );
  const dispatch = useDispatch();

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists

    return {
      headers: {
        ...headers,
        authorization: adminJsession ? `Bearer ${adminJsession}` : "",
        "keep-alive": "true",
      },
    };
  });
  const errorLink = onError(({ graphQLErrors, networkError, response }) => {
    console.log("graphQLErrors", graphQLErrors);
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, extensions }) => {
        if (extensions.code === "UNAUTHENTICATED") {
          console.log("UNAUTHENTICATED");
          dispatch(setAdminJsession(""));
          dispatch(setInitialStatePersistentState());
        }
        if (extensions.code === "BAD_USER_INPUT") {
          console.log("BAD_USER_INPUT");
          console.log("graphQLErrors", graphQLErrors);
          if (graphQLErrors?.length > 0) {
            graphQLErrors?.map((ele) => {
              setOpenSnackBarMessage(ele?.message);
            });
          }
          handleOpenSnackbar();
        }
      });
    }
    if (networkError) console.log(`[Network error]: ${networkError}`);
  });
  const httpLink = authLink.concat(
    createUploadLink({
      uri: BaseUrl,
    })
  );
  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: from([errorLink, httpLink]),
  });
  return (
    <ApolloProvider client={client}>
      <ThemeProvider>
        {/* <ThemeSetter /> */}
        <AppRoutes />
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          message={snackbarMessage}
        />
      </ThemeProvider>
    </ApolloProvider>
  );
}

function AppRoutes() {
  const history = createBrowserHistory();

  return (
    <Router history={history}>
      <Switch>
        {/*
         *if jsession present then it redirects to /requests else login page
         */}
        {/* <PublicRoute
          exact
          path="/"
          component={LandingPage}
          layout={MasterLayout}
        /> */}
        <LoginRoute
          exact
          path="/login"
          component={AdminLogin}
          layout={LoginLayout}
        />

        {/* <AdminRoute
          exact
          path="/admin"
          component={AdminDashboard}
          layout={AdminLayout}
        /> */}

        {/* GALLERY */}
        <AdminRoute
          exact
          path="/gallery"
          component={GalleryPage}
          layout={AdminLayout}
        />
        {/* PRODUCT */}
        <AdminRoute
          exact
          path="/products"
          component={Product}
          layout={AdminLayout}
        />
        <AdminRoute
          exact
          path="/products/create"
          component={ProductCreate}
          layout={AdminLayout}
        />
        <AdminRoute
          exact
          path="/products/:id"
          component={ProductCreate}
          layout={AdminLayout}
        />

        <AdminRoute
          exact
          path="/categories"
          component={Category}
          layout={AdminLayout}
        />

        <AdminRoute
          exact
          path="/categories/create"
          component={Category}
          layout={AdminLayout}
        />
        <AdminRoute
          exact
          path="/categories/:id"
          component={Category}
          layout={AdminLayout}
        />
        {/* ACCOUNTING CATEGORY END*/}
        {/* USER*/}
        <AdminRoute
          exact
          path="/users"
          component={UserPage}
          layout={AdminLayout}
        />

        <AdminRoute
          exact
          path="/users/create"
          component={UserPage}
          layout={AdminLayout}
        />
        <AdminRoute
          exact
          path="/users/:id"
          component={UserPage}
          layout={AdminLayout}
        />
        {/* USER END*/}

        <AdminRoute
          exact
          path="/orders"
          component={AdminOrder}
          layout={AdminLayout}
        />

        <AdminRoute
          exact
          path="/orders/create"
          // component={AccountCreate}
          layout={AdminLayout}
        />
        <AdminRoute
          exact
          path="/orders/:id"
          // component={AccountCreate}
          layout={AdminLayout}
        />
        {/* ORDERS END*/}
        <PublicRoute
          exact
          path="/login"
          component={NoRoute}
          layout={LoginLayout}
        />
        <AdminRoute
          exact
          path="/logout"
          component={Logout}
          layout={AdminLayout}
        />

        <PublicRoute
          exact
          path="/category"
          component={Category}
          layout={MasterLayout}
        />

        <PublicRoute
          component={() => <Redirect to={"/products"} />}
          layout={LoginLayout}
        />
      </Switch>
    </Router>
  );
}
export default App;
